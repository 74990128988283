/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { db } from '../services/config/firebase';
import { 
  doc, setDoc, collection, addDoc, updateDoc 
} from "firebase/firestore";

import '../styles/Tools.scss';


interface Field {
    key: string;
    value: string | Record<string, any>;
    isObject: boolean;
}

const Tools: React.FC = () => {
    const [docPath, setDocPath] = useState<string>('');
    const [isDocNew, setIsDocNew] = useState<boolean>(false);
    const [fields, setFields] = useState<Field[]>([{ key: '', value: '', isObject: false }]);

    // Handle changes to the fields
    const handleFieldChange = (index: number, key: string, value: string, isObject: boolean) => {
        const updatedFields = [...fields];
        updatedFields[index] = { key, value, isObject };
        setFields(updatedFields);
    };

    // Handle checkbox for determining if the value should be an object
    const handleIsObjectChange = (index: number, isObject: boolean) => {
        const updatedFields = [...fields];
        updatedFields[index].isObject = isObject;
        if (isObject) {
            updatedFields[index].value = '{}'; // default empty object string
        }
        setFields(updatedFields);
    };

    // Add new field to the form
    const addField = () => {
        setFields([...fields, { key: '', value: '', isObject: false }]);
    };

    // Submit form data to Firestore
    const submitDocument = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const docRef = docPath ? doc(db, docPath) : doc(collection(db, 'defaultCollection'), 'dummyDocId');
            
            const data = fields.reduce((acc, field) => {
                if (field.key) {
                    acc[field.key] = field.isObject ? JSON.parse(field.value as string) : field.value;
                }
                return acc;
            }, {} as Record<string, any>);

            if (isDocNew) {
                await addDoc(collection(db, docPath || 'defaultCollection'), data);
                alert('New document created!');
            } else {
                await setDoc(docRef, data, { merge: true });
                alert('Document updated!');
            }
        } catch (error) {
            console.error('Error writing document:', error);
        }
    };

    return (
        <div className='tools-page'>
            <h1>Firestore Tools</h1>
            <form onSubmit={submitDocument}>
                <div>
                    <label htmlFor="docPath">Document Path:</label>
                    <input 
                        type="text" 
                        id="docPath" 
                        value={docPath} 
                        onChange={(e) => setDocPath(e.target.value)}
                        placeholder="Enter document path (e.g., 'users/userId')"
                    />
                </div>
                <div>
                    <label htmlFor="newDoc">New Document:</label>
                    <input 
                        type="checkbox" 
                        id="newDoc" 
                        checked={isDocNew}
                        onChange={(e) => setIsDocNew(e.target.checked)}
                    />
                </div>
                {fields.map((field, index) => (
                    <div key={index}>
                        <label htmlFor={`field-key-${index}`}>Field Key:</label>
                        <input 
                            type="text" 
                            id={`field-key-${index}`} 
                            value={field.key}
                            onChange={(e) => handleFieldChange(index, e.target.value, field.value as string, field.isObject)}
                            placeholder="Field key"
                        />
                        {!field.isObject && (
                            <>
                                <label htmlFor={`field-value-${index}`}>Field Value:</label>
                                <input 
                                    type="text" 
                                    id={`field-value-${index}`} 
                                    value={field.value as string}
                                    onChange={(e) => handleFieldChange(index, field.key, e.target.value, field.isObject)}
                                    placeholder="Field value"
                                />
                            </>
                        )}
                        {field.isObject && (
                            <>
                                <label htmlFor={`field-value-object-${index}`}>Object Value (JSON):</label>
                                <textarea
                                    id={`field-value-object-${index}`}
                                    value={field.value as string}
                                    onChange={(e) => handleFieldChange(index, field.key, e.target.value, field.isObject)}
                                    placeholder='{"key": "value"}'
                                />
                            </>
                        )}
                        <label htmlFor={`field-is-object-${index}`}>Is Object:</label>
                        <input 
                            type="checkbox" 
                            id={`field-is-object-${index}`} 
                            checked={field.isObject}
                            onChange={(e) => handleIsObjectChange(index, e.target.checked)}
                        />
                    </div>
                ))}
                <button type="button" onClick={addField}>
                    Add Field
                </button>
                <button type="submit">
                    {isDocNew ? 'Create Document' : 'Update Document'}
                </button>
            </form>
        </div>
    );
};

export default Tools;


// const docRef = docPath ? doc(db, docPath) : doc(collection(db, 'defaultCollection'), 'dummyDocId');