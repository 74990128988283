import React, { 
    useEffect, 
    useState 
} from "react";



export const OutputTab: React.FC = () => {

    return (
        <div className="">
            <h1>Output Tab</h1>
        </div>
    );
}