// src/App.tsx
import React, { 
 useState 
} from 'react';

import {
  BrowserRouter as Router, 
  Route, 
  Routes 
} from 'react-router-dom';

import './App.scss';


import Header from './interface/components/common/Header';
import Sidebar from './interface/components/common/Sidebar';

import HomePage from './pages/HomePage';
import SubscriptionsPage from './pages/SubscriptionsPage';
import RevenuePage from './pages/RevenuePage';
import LoginPage from './pages/LoginPage';
import ConfigPage from './pages/ConfigPage';
import Tools from './pages/Tools';
import Playground from './pages/Playground';

import { AuthProvider } from './interface/contexts/AuthContext';
import PrivateRoute from './interface/components/admin/PrivateRoute';
import { ConfigProvider } from './interface/contexts/ConfigContext';
import { ToastContainer } from 'react-toastify';
// context
import { 
  PlaygroundProvider,
} from './interface/components/playground/ui/PlaygroundContext';


function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };



  return (
    <AuthProvider>
      <ConfigProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <Header 
                    toggleSidebar={toggleSidebar} 
                    isSidebarOpen={isSidebarOpen}
                  />
                  <Sidebar isOpen={isSidebarOpen} />
                  <main className={isSidebarOpen ? "open-main": "main-content"}>
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/subscriptions" element={<SubscriptionsPage />} />
                      <Route path="/revenue" element={<RevenuePage />} />
                      <Route path="/config" element={<ConfigPage />} />
                      <Route path="/tools" element={<Tools />} />
                      <Route path="/playground" element={<PlaygroundProvider><Playground /></PlaygroundProvider>} />
                    </Routes>
                  </main>
                </PrivateRoute>
              }
              />
          </Routes>
        </div>
      </Router>
      <ToastContainer /> {/* Add ToastContainer to render toasts */}
              </ConfigProvider>

    </AuthProvider>
  );
}

export default App;



