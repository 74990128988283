/* eslint-disable react-hooks/exhaustive-deps */
import React, { 
  useState, useEffect,

} from 'react';
import { Bar } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  Title, 
  Tooltip, 
  Legend 
} from 'chart.js';

import '../styles/RevenuePage.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


interface CostFactor {
  name: string;
  value: number;
  unit: string;
}

interface Variable {
  name: string;
  value: number;
  min: number;
  max: number;
  step: number;
}

interface Simulation {
  id: number;
  variables: Variable[];
  totalCost: number;
}

interface CostSimulation {
  id: number;
  variables: Variable[];
  totalCost: number;
}

interface CostModelDashboardProps {
  onCostUpdate: (cost: number, users: number) => void, 
  onSimulationSave: (simulation: CostSimulation) => void
}

export const CostModelDashboard: React.FC<CostModelDashboardProps> = ({ onCostUpdate, onSimulationSave }) => {
  const [costFactors, setCostFactors] = useState<CostFactor[]>([
    { name: 'AWS Textract', value: 0.0015, unit: '$ per page' },
    { name: 'OpenAI API', value: 0.002, unit: '$ per 1000 tokens' },
    { name: 'Firebase Storage', value: 0.026, unit: '$ per GB/month' },
    { name: 'Firestore Reads', value: 0.108, unit: '$ per 100,000 reads' },
    { name: 'Firestore Writes', value: 0.108, unit: '$ per 100,000 writes' },
    { name: 'Cloud Functions', value: 0.40, unit: '$ per million invocations' },
    { name: 'Bandwidth', value: 0.12, unit: '$ per GB' },
  ]);

  const [variables, setVariables] = useState<Variable[]>([
    { name: 'Users', value: 1000, min: 100, max: 10000, step: 100 },
    { name: 'Scans per User', value: 5, min: 1, max: 20, step: 1 },
    { name: 'LLM Messages per Scan', value: 3, min: 1, max: 10, step: 1 },
  ]);

  const [totalCost, setTotalCost] = useState<number>(0);
  const [simulations, setSimulations] = useState<Simulation[]>([]);
  const [chartData, setChartData] = useState<any>(null);

  const calculateCost = (vars: Variable[], factors: CostFactor[]): number => {
    const [users, scansPerUser, llmMessagesPerScan] = vars.map(v => v.value);
    const [textractCost, llmCost, storageCost, firestoreReadCost, firestoreWriteCost, cloudFunctionsCost, bandwidthCost] = factors.map(cf => cf.value);

    // Calculate individual costs
    const textractTotalCost = users * scansPerUser * textractCost;
    const llmTotalCost = users * scansPerUser * llmMessagesPerScan * (500 / 1000) * llmCost;
    const storageTotalCost = users * scansPerUser * 2 * storageCost / 1024;
    const firestoreReadsTotalCost = users * scansPerUser * 3 * firestoreReadCost / 100000;
    const firestoreWritesTotalCost = users * scansPerUser * 2 * firestoreWriteCost / 100000;
    const cloudFunctionsTotalCost = (users * scansPerUser * cloudFunctionsCost / 1000000) + (users * scansPerUser * 2 * 256 * 0.00001 / 1024);
    const bandwidthTotalCost = users * scansPerUser * 2 * bandwidthCost / 1024;

    // Sum up all costs
    const totalCost = 
      textractTotalCost + llmTotalCost + storageTotalCost + 
      firestoreReadsTotalCost + firestoreWritesTotalCost + 
      cloudFunctionsTotalCost + bandwidthTotalCost
    ;

    onCostUpdate(totalCost, users);
    return totalCost;
  };

  useEffect(() => {
    const cost = calculateCost(variables, costFactors);
    setTotalCost(cost);
  }, [variables, costFactors]);

  const handleVariableChange = (index: number, newValue: number) => {
    const updatedVariables = [...variables];
    updatedVariables[index] = {...updatedVariables[index], value: newValue};
    setVariables(updatedVariables);
  };

  const handleCostFactorChange = (index: number, newValue: number) => {
    const updatedCostFactors = [...costFactors];
    updatedCostFactors[index] = {...updatedCostFactors[index], value: newValue};
    setCostFactors(updatedCostFactors);
  };

  const saveSimulation = () => {
    const newSimulation: CostSimulation = {
      id: Date.now(),
      variables: [...variables],
      totalCost: totalCost
    };
    setSimulations([...simulations, newSimulation]);
    onSimulationSave(newSimulation);
  };

  useEffect(() => {
    if (simulations.length > 0) {
      const labels = simulations.map((sim, index) => `Sim ${index + 1}`);
      const data = simulations.map(sim => sim.totalCost);

      setChartData({
        labels,
        datasets: [
          {
            label: 'Total Cost per Month',
            data,
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgb(75, 192, 192)',
            borderWidth: 1
          }
        ]
      });
    }
  }, [simulations]);

  return (
    <div className="cost-model-dashboard">
      <h1>Cost Model Dashboard</h1>
      
      <div className="inputs-container">
        <div className="variables">
          <h2>Variables</h2>
          {variables.map((variable, index) => (
            <div key={variable.name} className="input-group">
              <div className="label-group">
                <label>{variable.name}</label>
              </div>
              
              <input
                type="number"
                min={variable.min}
                max={variable.max}
                step={variable.step}
                value={variable.value}
                onChange={(e) => handleVariableChange(index, parseFloat(e.target.value))}
              />
            </div>
          ))}
        </div>

        <div className="cost-factors">
          <h2>Cost Factors</h2>
          {costFactors.map((factor, index) => (
            <div key={factor.name} className="input-group">
              <div className="label-group">
                <label>{factor.name}</label>
              </div>
              <div className="input-with-unit">
                <input
                  type="number"
                  step="0.0001"
                  value={factor.value}
                  onChange={(e) => handleCostFactorChange(index, parseFloat(e.target.value))}
                />
                <span className="unit">{factor.unit}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="results">
        <h2>Results</h2>
        <p>Total Cost per Month: ${totalCost.toFixed(2)}</p>
        <p>Cost per User: ${(totalCost / variables[0].value).toFixed(2)}</p>
        <button onClick={saveSimulation}>Save Simulation</button>
      </div>

      <div className="chart">
        <h2>Cost Comparison</h2>
        {chartData && <Bar data={chartData} />}
      </div>

      <div className="saved-simulations">
        <h2>Saved Simulations</h2>
        <div className="simulations-container">
          {simulations.map((sim, index) => (
            <div key={sim.id} className="simulation">
              <h3>Simulation {index + 1}</h3>
              {sim.variables.map(v => (
                <p key={v.name}>{v.name}: {v.value}</p>
              ))}
              <p>Total Cost: ${sim.totalCost.toFixed(2)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface ProfitFactors {
  paymentModel: 'payAsYouGo' | 'subscription';
  userPaymentAmount: number;
  appStoreFeePercentage: number;
  taxRate: number;
  userAcquisitionCost: number;
  serverCosts: number;
  marketingCosts: number;
  supportCosts: number;
  trialCosts: number;
}

interface ProfitSimulation {
  id: number;
  factors: ProfitFactors;
  revenue: number;
  costs: number;
  profit: number;
}

export const ProfitCalculator: React.FC<{ 
  totalCost: number; userCount: number; onSimulationSave: (simulation: ProfitSimulation) => void 
}> = ({ totalCost, userCount, onSimulationSave }) => {
  // ... existing code ...
  const [profitFactors, setProfitFactors] = useState<ProfitFactors>({
    paymentModel: 'subscription',
    userPaymentAmount: 9.99,
    appStoreFeePercentage: 30,
    taxRate: 20,
    userAcquisitionCost: 5,
    serverCosts: 1000,
    marketingCosts: 2000,
    supportCosts: 1500,
    trialCosts: totalCost
  });

  const [simulations, setSimulations] = useState<ProfitSimulation[]>([]);
  const [chartData, setChartData] = useState<any>(null);

  const calculateProfit = (factors: ProfitFactors): { revenue: number; costs: number; profit: number } => {
    const grossRevenue = userCount * factors.userPaymentAmount;
    const appStoreFees = grossRevenue * (factors.appStoreFeePercentage / 100);
    const taxAmount = (grossRevenue - appStoreFees) * (factors.taxRate / 100);
    
    const revenue = grossRevenue - appStoreFees - taxAmount;
    
    const costs = //totalCost + 
                  (userCount * factors.userAcquisitionCost) + 
                  factors.serverCosts + 
                  factors.marketingCosts + 
                  factors.supportCosts + factors.trialCosts
                  ;
    
    const profit = revenue - costs;
    
    return { revenue, costs, profit };
  };

  const handleFactorChange = (factor: keyof ProfitFactors, value: any) => {
    setProfitFactors(prev => ({ ...prev, [factor]: value }));
  };

  const saveSimulation = () => {
    const { revenue, costs, profit } = calculateProfit(profitFactors);
    const newSimulation: ProfitSimulation = {
      id: Date.now(),
      factors: { ...profitFactors },
      revenue,
      costs,
      profit
    };
    setSimulations([...simulations, newSimulation]);
    onSimulationSave(newSimulation);
  };

  useEffect(() => {
    if (simulations.length > 0) {
      const labels = simulations.map((sim, index) => `Sim ${index + 1}`);
      const revenueData = simulations.map(sim => sim.revenue);
      const costsData = simulations.map(sim => sim.costs);
      const profitData = simulations.map(sim => sim.profit);

      setChartData({
        labels,
        datasets: [
          {
            label: 'Revenue',
            data: revenueData,
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
          },
          {
            label: 'Costs',
            data: costsData,
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
          },
          {
            label: 'Profit',
            data: profitData,
            backgroundColor: 'rgba(255, 206, 86, 0.6)',
          }
        ]
      });
    }
  }, [simulations]);

  return (
    <div className="profit-calculator">
      <h2>Profit Calculator</h2>
      
      <div className="profit-inputs">
        <div className="input-group">
          <label>Payment Model</label>
          <select 
            value={profitFactors.paymentModel} 
            onChange={(e) => handleFactorChange('paymentModel', e.target.value)}
          >
            <option value="payAsYouGo">Pay As You Go</option>
            <option value="subscription">Subscription</option>
          </select>
        </div>
        
        <div className="input-group">
          <label>User Payment Amount ($)</label>
          <input 
            type="number" 
            value={profitFactors.userPaymentAmount} 
            onChange={(e) => handleFactorChange('userPaymentAmount', parseFloat(e.target.value))}
          />
        </div>
        
        <div className="input-group">
          <label>App Store Fee (%)</label>
          <input 
            type="number" 
            value={profitFactors.appStoreFeePercentage} 
            onChange={(e) => handleFactorChange('appStoreFeePercentage', parseFloat(e.target.value))}
          />
        </div>
        
        <div className="input-group">
          <label>Tax Rate (%)</label>
          <input 
            type="number" 
            value={profitFactors.taxRate} 
            onChange={(e) => handleFactorChange('taxRate', parseFloat(e.target.value))}
          />
        </div>
        
        <div className="input-group">
          <label>User Acquisition Cost ($)</label>
          <input 
            type="number" 
            value={profitFactors.userAcquisitionCost} 
            onChange={(e) => handleFactorChange('userAcquisitionCost', parseFloat(e.target.value))}
          />
        </div>
        
        <div className="input-group">
          <label>Server Costs ($)</label>
          <input 
            type="number" 
            value={profitFactors.serverCosts} 
            onChange={(e) => handleFactorChange('serverCosts', parseFloat(e.target.value))}
          />
        </div>
        
        <div className="input-group">
          <label>Marketing Costs ($)</label>
          <input 
            type="number" 
            value={profitFactors.marketingCosts} 
            onChange={(e) => handleFactorChange('marketingCosts', parseFloat(e.target.value))}
          />
        </div>
        
        <div className="input-group">
          <label>Support Costs ($)</label>
          <input 
            type="number" 
            value={profitFactors.supportCosts} 
            onChange={(e) => handleFactorChange('supportCosts', parseFloat(e.target.value))}
          />
        </div>
        <div className="input-group">
          <label>Free Trial Costs ($)</label>
          <input 
            type="number" 
            value={profitFactors.trialCosts} 
            onChange={(e) => handleFactorChange('trialCosts', parseFloat(e.target.value))}
          />
        </div>
      </div>

      <div className="profit-results">
        <h3>Results</h3>
        {calculateProfit(profitFactors).profit > 0 ? (
          <p className="profit">Estimated Profit: ${calculateProfit(profitFactors).profit.toFixed(2)}</p>
        ) : (
          <p className="loss">Estimated Loss: ${Math.abs(calculateProfit(profitFactors).profit).toFixed(2)}</p>
        )}
        <button onClick={saveSimulation}>Save Simulation</button>
      </div>

      <div className="profit-chart">
        <h3>Profit vs Cost Comparison</h3>
        {chartData && <Bar data={chartData} />}
      </div>

      <div className="saved-simulations">
        <h3>Saved Simulations</h3>
        {simulations.map((sim, index) => (
          <div key={sim.id} className="simulation">
            <h4>Simulation {index + 1}</h4>
            <p>Payment Model: {sim.factors.paymentModel}</p>
            <p>User Payment: ${sim.factors.userPaymentAmount}</p>
            <p>Revenue: ${sim.revenue.toFixed(2)}</p>
            <p>Costs: ${sim.costs.toFixed(2)}</p>
            <p>Profit: ${sim.profit.toFixed(2)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};



const RevenuePage: React.FC = () => {
  const [totalCost, setTotalCost] = useState(0);
  const [userCount, setUserCount] = useState(1000);
  const [costSimulations, setCostSimulations] = useState<CostSimulation[]>([]);
  const [profitSimulations, setProfitSimulations] = useState<ProfitSimulation[]>([]);

  const handleCostUpdate = (cost: number, users: number) => {
    setTotalCost(cost);
    setUserCount(users);
  };

  const handleCostSimulationSave = (simulation: CostSimulation) => {
    setCostSimulations([...costSimulations, simulation]);
  };

  const handleProfitSimulationSave = (simulation: ProfitSimulation) => {
    setProfitSimulations([...profitSimulations, simulation]);
  };

  React.useEffect(() => {
    console.log('Total Cost:', totalCost);
    console.log('User Count:', userCount);
  }, [totalCost, userCount]);

  return (
    <div className="revenue-page">
      <div className="main-content">
        <h1>Revenue Analysis</h1>
        <CostModelDashboard onCostUpdate={handleCostUpdate} onSimulationSave={handleCostSimulationSave} />
        <ProfitCalculator totalCost={totalCost} userCount={userCount} onSimulationSave={handleProfitSimulationSave} />
      </div>
      <div className="simulations-sidebar">
        <h2>Saved Simulations</h2>
        <div className="simulations-container">
          <div className="cost-simulations">
            <h3>Cost Simulations</h3>
            {costSimulations.map((sim, index) => (
              <div key={sim.id} className="simulation">
                <h4>Simulation {index + 1}</h4>
                <p>Total Cost: ${sim.totalCost.toFixed(2)}</p>
                {/* Add more details as needed */}
              </div>
            ))}
          </div>
          <div className="profit-simulations">
            <h3>Profit Simulations</h3>
            {profitSimulations.map((sim, index) => (
              <div key={sim.id} className="simulation">
                <h4>Simulation {index + 1}</h4>
                <p>Revenue: ${sim.revenue.toFixed(2)}</p>
                <p>Costs: ${sim.costs.toFixed(2)}</p>
                <p>Profit: ${sim.profit.toFixed(2)}</p>
                {/* Add more details as needed */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenuePage;