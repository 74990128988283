

import { 
    getStorage, FirebaseStorage
} from "firebase/storage"

import {
    Storage,
    Bucket
} from "@google-cloud/storage"
import { 
    TextractClient, 
    AnalyzeDocumentCommandOutput, ListAdaptersCommand,
    AnalyzeDocumentCommandInput, AnalyzeDocumentCommand, 
    BlockType, Block, DetectDocumentTextCommand, AnalyzeDocumentResponse
} from "@aws-sdk/client-textract"; // ES Modules import

import * as path from 'path';

import {
    StorageManager 
} from "../../db";

export class Extractor {

    private readonly storageManager: StorageManager;
    private readonly textClient: TextractClient;

    public cName: string = "Extractor";
    
    public textractTree: TextractTree | null = null;
    /**
     * Initialize the Extractor class
     * @throws Error if initialization fails
     */
    constructor() {
        try {
            this.storageManager = StorageManager.getInstance();
            // aws config 
            const region = process.env.REACT_APP_AWS_REGION || 'us-west-2';
            this.textClient = new TextractClient({ 
                region, 
                credentials: {
                    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID!,    
                    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY_ID!
                }
            });
            this.textractTree = null;

        } catch (error) {
            console.error(this.cName, "constructor", error);
            throw error;
        }
    }
    /**
     * Retrieve an image from Firebase storage
     * @param bucket - The name of the storage bucket
     * @param filePath - The path to the file within the bucket
     * @returns A Buffer containing the image data
     * @throws Error if image retrieval fails
     */
    async getImage(filePath: string): Promise<ArrayBuffer> {
        try {
            const url = await this.storageManager.getDownloadURL(filePath);
            // fetch the image from the url and return the buffer
            const response = await fetch(url);
            // convert the response to a buffer
            const buffer = await response.arrayBuffer();

            return buffer;
        } catch (error) {
            console.error(this.cName, "getImage", error);
            throw error
        }
    }

    /**
     * Extract text from an image using AWS Textract
     * @param filePath - The path to the image file within the bucket
     * @returns The path where the Textract analysis results were stored
     * @throws Error if text extraction or storage fails
     */
    async getTextract(imageBytes: Uint8Array): Promise<AnalyzeDocumentResponse["Blocks"]> {
        try {
            const input: AnalyzeDocumentCommandInput = {
                FeatureTypes: ["TABLES", "FORMS"],
                Document: {
                    Bytes: imageBytes
                }
            };

            const data = await this.textClient.send(new AnalyzeDocumentCommand(input));
            
            if (!data || !data.Blocks) {throw new Error('No data or blocks detected in the image');}
            const blocks = data.Blocks;

            return blocks
        } catch (error) {
            console.error(this.cName, "getTextract", error);
            throw error
        }
    }
    /**
     * Store Textract analysis results in Firebase storage
     * @param bucket - The name of the storage bucket
     * @param simId - The original file path (used to determine storage location)
     * @param textractJson - The Textract analysis results to store
     * @returns {TextractTree} The path where the Textract data was stored
     * @throws Error if storing Textract data fails
     */
    async storeTextract(simId: string, blocks: AnalyzeDocumentCommandOutput["Blocks"]): Promise<TextractTree> {
        try {
            if(!blocks) throw new Error('No blocks detected');
            const json = JSON.stringify(blocks);
            const arrayBuffer = new Uint8Array(Buffer.from(json));

            // 
            const simPath = `textract/${simId}/textract.json`;
            const upload = await this.storageManager.uploadFile(
                simPath, arrayBuffer
            )
            console.log('upload:', upload);
            // Initialize the Textract tree
            this.textractTree = new TextractTree(blocks);
            return this.textractTree;
        } catch (error) {
            console.error(this.cName, "storeTextract", error);
            throw error
        }
    }
}





export interface TextractObject {
    textractTrees: { [key: string]: TextractTree };
    textractBlocks: { [key: string]: Block[] };
}

export type TextractNode = Block & {
    nodes: Map<string, TextractNode>;
};

export interface TableCell extends TextractNode {
    RowIndex?: number;
    ColumnIndex?: number;
}

export class TextractTree {
    private rootNode: TextractNode | null = null;
    private blockMap: Map<string, TextractNode> = new Map<string, TextractNode>();

    constructor(blocks: Block[]) {
        this.buildTree(blocks);
    }

    private buildTree(blocks: Block[]): void {
        // Create initial nodes and map them by Id
        blocks.forEach(block => {
            this.blockMap.set(block.Id!, {
                ...block,
                nodes: new Map<string, TextractNode>(),
            })
        });

        // Establish relationships
        blocks.forEach(block => {
            const node = this.blockMap.get(block.Id!);
            if (node) {
                if (block.BlockType === 'PAGE') {
                    this.rootNode = node;
                }
                if (block.Relationships) {
                    block.Relationships.forEach(relationship => {
                        if (relationship.Type === 'CHILD') {
                            relationship.Ids!.forEach(childId => {
                                const childNode = this.blockMap.get(childId);
                                if (childNode) {
                                    node.nodes.set(childId, childNode);
                                }
                            });
                        }
                    });
                }
            }
        });

        if (!this.rootNode) {
            throw new Error("Root node not found");
        }
    }

    public getKeyValues(): { key: string; value: string }[] {
        const keyValues: { key: string; value: string }[] = [];
        this.blockMap.forEach((block, blockId) => {
            if (block.BlockType === 'KEY_VALUE_SET' && block.EntityTypes?.includes('KEY')) {
                const keyText = this.extractText(block);
                const valueBlockIds = block.Relationships?.find(rel => rel.Type === 'VALUE')?.Ids || [];
                const valueText = valueBlockIds.map(id => {
                    const valueBlock = this.blockMap.get(id);
                    return valueBlock ? this.extractText(valueBlock) : '';
                }).join(' ');
                keyValues.push({ key: keyText, value: valueText });
            }
        });
        return keyValues;
    }

    public getKeyTextForValue(valueId: string): string {
        const valueBlock = this.blockMap.get(valueId);
        if (!valueBlock) return '';

        for (const [blockId, block] of this.blockMap.entries()) {
            if (
                block.BlockType === 'KEY_VALUE_SET' &&
                block.EntityTypes?.includes("KEY") &&
                block.Relationships
            ) {
                for (const relationship of block.Relationships) {
                    if (relationship.Ids!.includes(valueId)) {
                        return this.extractText(block);
                    }
                }
            }
        }

        return '';
    }

    public extractText(block: TextractNode): string {
        if (!block || !block.nodes) return '';
        const textNodes = Array.from(block.nodes.values()).filter(node => 
            node.BlockType === 'WORD' || node.BlockType === 'LINE'
        );
        return textNodes.map(node => node.Text).join(' ');
    }

    public extractTextAndKeys(): { allText: string[]; keyText: string[] } {
        const text: {allText: string[], keyText: string[]} = {
            allText: [],
            keyText: [],
        };

        this.blockMap.forEach((block, blockId) => {
            if (block.BlockType === 'KEY_VALUE_SET') {
                const isKey = block.EntityTypes?.includes('KEY');
                const isValue = block.EntityTypes?.includes('VALUE');

                let keyText = "";
                let valueText = "";

                const children = this.getChildren(block);
                children.forEach(child => {
                    if (child.BlockType === 'WORD') {
                        if (isKey) keyText += `${child.Text} `;
                        if (isValue) valueText += `${child.Text} `;
                    }
                });

                if (isValue) {
                    const valueParentKeyText = this.getKeyTextForValue(block.Id!);
                    keyText += valueParentKeyText;
                }

                keyText = keyText.trim();
                valueText = valueText.trim();

                if (isKey) {
                    text.keyText.push(keyText);
                }
            }
            if (block.BlockType === 'WORD' || block.BlockType === 'LINE') {
                const textValue = this.extractText(block);
                if (textValue.length > 0) {
                    text.allText.push(textValue);
                }
            }
        });

        return text;
    }


    public getNodeByCoordinates(coordinates: { 
        x: number; y: number; buffer: number; scaleFactors?: { x: number; y: number }
    }): TextractNode | null {
        try {
            let { x, y, buffer, scaleFactors } = coordinates;
            if (!this.rootNode) return null;
            if (scaleFactors) {
                x = x / scaleFactors.x;
                y = y / scaleFactors.y;
            }
            let matchingNode: TextractNode | null = null;
            this.rootNode.nodes.forEach(node => {
                if(!node.Geometry?.Polygon || !node.Geometry?.BoundingBox) return;
                const { Left, Top, Width, Height } = node.Geometry.BoundingBox;
                const dims = {
                    X: node.Geometry.Polygon[0].X,
                    Y: node.Geometry.Polygon[0].Y,
                    X1: node.Geometry.Polygon[1].X,
                    Y1: node.Geometry.Polygon[2].Y
                };
                if(!dims.X || !dims.Y ||!dims.X1 || !dims.Y1) return;
                if (x >= dims.X - buffer && x <= dims.X1 + buffer && 
                    y >= dims.Y - buffer && y <= dims.Y1 + buffer) {
                    matchingNode = node;
                }
            });
            return matchingNode;
        } catch (error) {
            console.error('getNodeByCoordinates error:', error);
            return null;
        }
    }

    public getTables(): any[][] {
        const tables: any[][] = [];
        this.blockMap.forEach((block, blockId) => {
            if (block.BlockType === 'TABLE') {
                const table: any[][] = [];
                let currentRow: any[] = [];
                let lastRowIndex = -1;

                const cellNodes = this.getChildren(block).filter(child => child.BlockType === 'CELL');
                cellNodes.sort((a, b) => {
                    const aY = a.Geometry!.BoundingBox!.Top!;
                    const bY = b.Geometry!.BoundingBox!.Top!;
                    const aX = a.Geometry!.BoundingBox!.Left!;
                    const bX = b.Geometry!.BoundingBox!.Left!;
                    return aY !== bY ? aY - bY : aX - bX;
                });

                cellNodes.forEach((cell: TableCell) => {
                    const rowIndex = cell.RowIndex || 0;
                    const columnIndex = cell.ColumnIndex || 0;

                    if (rowIndex !== lastRowIndex) {
                        if (currentRow.length > 0) {
                            table.push(currentRow);
                        }
                        currentRow = [];
                        lastRowIndex = rowIndex;
                    }

                    currentRow[columnIndex - 1] = this.extractText(cell);
                });

                if (currentRow.length > 0) {
                    table.push(currentRow);
                }

                tables.push(table);
            }
        });
        return tables;
    }

    public getNodeById(id: string): TextractNode | undefined {
        return this.blockMap.get(id);
    }

    public getChildren(node: TextractNode): TextractNode[] {
        return Array.from(node.nodes.values());
    }

    public getRootNode(): TextractNode | null {
        return this.rootNode;
    }

    public getBlockMap(): Map<string, TextractNode> {
        return this.blockMap;
    }
}

