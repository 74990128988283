import React, { useEffect, useState } from 'react';
import { useConfig } from '../../../contexts/ConfigContext';
import {  z } from 'zod';
// create plan is used to to create plan and update is use to update plan
import { createPlan, updatePlan } from '../../../../services/firebase';
// types for plan
import { Subscription } from '../../../../types/config';
// It is used for Notification
import { toast } from 'react-toastify'; // Import React Toastify
import 'react-toastify/dist/ReactToastify.css'; // Import React Toastify CSS

// Define the Zod schema for validation
const schema = z.object({
  name: z.string().min(1, "Plan name is required"),
  price: z.number().min(0, "Price must be positive"),
  currency: z.string().min(1, "Currency is required"),
  billingCycle: z.enum(["monthly", "quarterly", "annually"], {
    errorMap: () => ({ message: "Billing cycle is required" }),
  }),
  description: z.string().min(1, "Plan description is required"),
  features: z.array(z.string())
    .min(3, "At least three features are required.")
    .nonempty("At least one feature is required."),});

type BillingCycle = "monthly" | "quarterly" | "annually"; // Type for BillingCycle

const Model: React.FC = () => {
  // subscriptionModel is for opening the model for adding and deleting also making plan available
  // reRender is use for reRendering the component
  const { subscriptionModel, setSubscriptionModel, reRender, setReRender ,available, editsubscribtionPlan, setEditSubscribtionPlan,setOpenDeleteDialog } = useConfig();

  const [name, setName] = useState('');
  const [price, setPrice] = useState( 0);
  const [currency, setCurrency] = useState('USD');
  const [billingCycle, setBillingCycle] = useState<BillingCycle>('monthly');
  const [description, setDescription] = useState('');
  const [features, setFeatures] = useState<string[]>([]); // State to manage features
  const [newFeature, setNewFeature] = useState(''); // State to manage the input feature
  const [isAvailable, setIsAvailable] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [disableCheckBox,setDisableCheckBox] = useState(false)
  const [buttonDisable,setButtonDisable] = useState(false)


  useEffect(() => {
    // archive all the data from the editsubscribtionPlan and set it to our model state
    setName(editsubscribtionPlan?.name || '');
    setPrice(editsubscribtionPlan?.price || 0);
    setCurrency(editsubscribtionPlan?.currency || 'USD');
    setBillingCycle(editsubscribtionPlan?.billingCycle || 'monthly');
    setDescription(editsubscribtionPlan?.description || '');
    setFeatures(editsubscribtionPlan?.features || []);
  }, [editsubscribtionPlan]);
  

  // Disable the checkbox if available.length is 3(if the available has 3 plans)
  useEffect(() => {
    setDisableCheckBox(available.length === 3);
  }, [available]);
  

  // Handle the checkbox change event for setting the availability option
  const handleCheckboxChange = () => {
    setIsAvailable(prevState => !prevState);
  };

  // Add a new feature to the list in the model
  const addFeature = () => {
    if (newFeature) {
      setFeatures([...features, newFeature]);
      setNewFeature(''); // Reset the input field
    }
  };

  // Remove a feature from the list in the model
  const removeFeature = (index: number) => {
    setFeatures(features.filter((_, i) => i !== index));
  };

  // Handle data submission
  const handleSubmit = async () => {
    const data: Omit<Subscription, 'id'> = {
      name,
      price,
      currency,
      billingCycle,
      description,
      features,
    };
  
    // Validate the data against the schema
    const result = schema.safeParse(data);
  
    if (!result.success) {
      // Extract validation errors
      const validationErrors = result.error.format();
      setErrors({
        name: validationErrors.name?._errors?.[0] || '',
        price: validationErrors.price?._errors?.[0] || '',
        currency: validationErrors.currency?._errors?.[0] || '',
        billingCycle: validationErrors.billingCycle?._errors?.[0] || '',
        description: validationErrors.description?._errors?.[0] || '',
        features: validationErrors.features?._errors?.[0] || '',
      });
    } else {
      try {
        // Check if we're editing an existing plan or creating a new one
        if (editsubscribtionPlan !== null) {
          // Editing an existing plan
          const updatedData: Subscription = {
            id: editsubscribtionPlan.id, // Keep the ID for updating
            ...data, // Use the form data
          };
  
          // Update the existing plan
          setButtonDisable(true)
          await updatePlan(editsubscribtionPlan.id, updatedData,isAvailable);
          setButtonDisable(false)
          toast.success('Plan updated successfully!');

        } else {
          // Creating a new plan
          setButtonDisable(true)

          await createPlan(data, isAvailable);
          setButtonDisable(false)

          toast.success('Plan created successfully!');
        }
  
        // Re-render or refresh state
        setReRender(!reRender);
        // making all the model state emty after operation happen
        setErrors({});
        setName('');
        setPrice(0);
        setBillingCycle('monthly');
        setCurrency('USD');
        setDescription('');
        setFeatures([]);
  
        // Reset form or close modal if needed
        setSubscriptionModel(false);
      } catch (error) {
        toast.error('Error processing plan. Please try again.');
      }
    }
  };
  


  const handleCloseModel = () => {
    // this is for closing the model 
    setSubscriptionModel(false)
    setEditSubscribtionPlan(null)
    setErrors({});


  }

  const  handleDeletePlans = async () => {
    // this is for dleeting the plan
    setOpenDeleteDialog(true)
    // making the model emty as we might delte the plan don't want to data to show in model
    setReRender(!reRender);
    setErrors({});
    setName('');
    setPrice(0);
    setBillingCycle('monthly');
    setCurrency('USD');
    setDescription('');
    setFeatures([]);

    // Reset form or close modal if needed
    setSubscriptionModel(false);
  
  }

  return (
    <>
      <div className={` ${subscriptionModel ? 'flex' : 'hidden'} overflow-y-auto h-[80%] overflow-x-hidden fixed top-0 mt-20 right-0 left-0 z-100 justify-center items-center w-full md:inset-0 z-50 max-h-full`}>
        <div className="relative p-4 w-full max-w-md max-h-full">
          {/* Modal content */}
          <div className="relative rounded-lg shadow bg-gray-700">
            {/* Modal header */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-600">
              <h3 className="text-lg font-semibold text-white">Create New Product</h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center hover:bg-gray-600 hover:text-white"
                onClick={() => handleCloseModel()}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-4 md:p-5">
              <div className="grid gap-4 mb-4 grid-cols-2">
                {/* Plan Name */}
                <div className="col-span-2">
                  <label htmlFor="name" className="block mb-2 text-sm font-medium text-white">
                    Plan Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
                    placeholder="Subscription plan name"
                  />
                  {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                </div>
                {/* Price */}
                <div className="col-span-2 sm:col-span-1">
                  <label htmlFor="price" className="block mb-2 text-sm font-medium text-white">
                    Price
                  </label>
                  <input
                    type="number"
                    id="price"
                    value={price}
                    onChange={(e) => setPrice(Number(e.target.value))}
                    className="text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
                    placeholder="$2999"
                  />
                  {errors.price && <p className="text-red-500 text-sm">{errors.price}</p>}
                </div>
                {/* Currency */}
                <div className="col-span-2 sm:col-span-1">
                  <label htmlFor="currency" className="block mb-2 text-sm font-medium text-white">
                    Currency
                  </label>
                  <select
                    id="currency"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                    className="text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
                  >
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="AUD">AUD</option>
                    <option value="INR">INR</option>
                  </select>
                  {errors.currency && <p className="text-red-500 text-sm">{errors.currency}</p>}
                </div>
                {/* Billing Cycle */}
                <div className="col-span-2">
                  <label htmlFor="billingCycle" className="block mb-2 text-sm font-medium text-white">
                    Billing Cycle
                  </label>
                  <select
                    id="billingCycle"
                    value={billingCycle}
                    onChange={(e) => setBillingCycle(e.target.value as BillingCycle)}
                    className="text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
                  >
                    <option value="monthly">Monthly</option>
                    <option value="quarterly">Quarterly</option>
                    <option value="annually">Annually</option>
                  </select>
                  {errors.billingCycle && <p className="text-red-500 text-sm">{errors.billingCycle}</p>}
                </div>
                {/* Description */}
                <div className="col-span-2">
                  <label htmlFor="description" className="block mb-2 text-sm font-medium text-white">
                    Description
                  </label>
                  <textarea
                    id="description"
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
                    placeholder="Plan description"
                  />
                  {errors.description && <p className="text-red-500 text-sm">{errors.description}</p>}
                </div>
                {/* Features */}
                <div className="col-span-2">
                  <label htmlFor="features" className="block mb-2 text-sm font-medium text-white">
                    Features
                  </label>
                  <div className="mb-2">
                    <input
                      type="text"
                      id="feature"
                      value={newFeature}
                      onChange={(e) => setNewFeature(e.target.value)}
                      className="text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 bg-gray-600 border-gray-500 placeholder-gray-400 text-white"
                      placeholder="Enter a feature"
                    />
                    <button
                      type="button"
                      onClick={addFeature}
                      className="mt-2 text-sm rounded-lg px-4 py-2 bg-gray-400 text-white "
                    >
                      Add Feature
                    </button>
                  </div>
                  <ul className="list-disc pl-5">
                    {features.map((feature, index) => (
                      <li key={index} className="flex justify-between items-center">
                        {feature}
                        <button
                          type="button"
                          onClick={() => removeFeature(index)}
                          className="text-red-500 ml-2"
                        >
                          Remove
                        </button>
                      </li>
                    ))}
                  </ul>
                  {errors.features && <p className="text-red-500 text-sm">{errors.features}</p>}
                </div>
                {/* Availability */}
                <div className="col-span-2 flex items-center">
                  <input
                    id="isAvailable"
                    type="checkbox"
                    disabled={disableCheckBox}  // Disable the checkbox if isAvailable is false
                    checked={isAvailable}
                    onChange={handleCheckboxChange}
                    className="w-4 h-4 text-primary-600 bg-gray-700 border-gray-600 rounded"
                  />
                  <label htmlFor="isAvailable" className="ml-2 text-sm font-medium text-white">
                    {disableCheckBox ? 'Aready 3 subscribtion plan are there' : 'Available'}
                  </label>
                </div>
              </div>
            </div>
            {/* Modal footer */}
            <div className="flex items-center justify-between p-4 md:p-5 border-t border-gray-600">
  <div className="space-x-2">
    <button
      type="button"
      className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      disabled={buttonDisable}
      onClick={handleSubmit}
    >
      {editsubscribtionPlan !== null ? 'Update' : 'Save'} 
    </button>
    <button
      type="button"
      className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
      onClick={() => handleCloseModel()}
    >
      Close
    </button>
  </div>
  {editsubscribtionPlan !== null &&  (<>
    <button
    type="button"
    className="ml-auto text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
    onClick={() => handleDeletePlans()}
  >
    Delete
  </button>
  </>) }

</div>


          </div>
        </div>
      </div>
    </>
  );
};

export default Model;
