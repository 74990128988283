import React, { useEffect, useState } from "react";
import { MissingInfoSchema } from "../../../types/config";
import {
  subscribeToToolSchema,
  updateChatById,
  updateToolSchema,
} from "../../../services/firebase";
import LlmTable from "./ui/LlmTable";
import LlmInit from "./ui/LlmInit";
import { useConfig } from "../../contexts/ConfigContext";
import { toast } from "react-toastify"; // Import React Toastify
import "react-toastify/dist/ReactToastify.css"; // Import React Toastify CSS

const LlmConfig: React.FC = () => {
  // This is selectedTab state used to Toggle between InitPromp" | "LLMConfig
  const [selectedTab, setSelectedTab] = useState<"InitPromp" | "LLMConfig">(
    "LLMConfig"
  );
  // This is toolSchemas where we store data for all toolSchemas
  const [toolSchemas, setToolSchemas] = useState<MissingInfoSchema[] | null>(
    null
  ); 

  const { dissableButton, inputValues, dataInitChat ,setInputValues} = useConfig();
  // dissableButton it is a state use to determine weather the state of fetchdata and changed data is same or not so that we make the button disable if the state is not change 
  // inputValues has the state of new data of toolschema
  // dataInitChat has the state of new data of InitChat

  // Fetching the real time data for toolschema
  useEffect(() => {
    const unsubscribe = subscribeToToolSchema(setToolSchemas);

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);



// This handleClick function updates the toolschema 
  const handleClick = async () => {
    try {
      // Call the updateToolSchema function and await the result
      // checking if the inputValues is not null
      if (inputValues !== null) {
        await updateToolSchema(inputValues[0].id, inputValues[0]);

        // after the task completed sucessfuly setting a notification
        toast.success("Data Updated sucessfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Failed to update. Please try again.");
    }
  };

  // This handleClickInit Function used for updating the InitChat

  const handleClickInit = async () => {
    try {
      // checking if the dataInitChat is not null 
      if (dataInitChat !== null) {

        await updateChatById(dataInitChat.id, dataInitChat?.text);
        // notification bar when the update of init chat is sucessful
        toast.success("InitChat updated successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error("Failed to update InitChat. Please try again.");
    }
  };
// It is and undo function (it will make the state of the Iput values  deafult to toolschemas) 
  const handleDefaultToolSchema = () => {
    setInputValues(toolSchemas)
  }

  return (
    <>
      <div className="flex items-center justify-center w-full ">
        {/* if selectedTab === "LLMConfig it will show us LLmConfig button to save the data  */}
        {selectedTab === "LLMConfig" && (
          <>
            <button
              type="button"
              className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              onClick={() => handleDefaultToolSchema()}
            >
              Default
            </button>
            <button
              type="button"
              className={`focus:outline-none text-white 
        hover:bg-green-800 
        focus:ring-4 focus:ring-green-300 
        font-medium rounded-lg 
        text-sm px-5 py-2.5 me-2 mb-2 
        ${
          dissableButton
            ? "bg-red-800 dark:bg-red-900"
            : "bg-green-700 dark:bg-green-600"
        } 
        ${
          dissableButton
            ? "cursor-not-allowed opacity-50"
            : "dark:hover:bg-green-700"
        }
      `}
              disabled={dissableButton}
              onClick={() => handleClick()}
            >
              Save
            </button>
          </>
        )}
    {/* If the  selectedTab === "InitPromp" it will show us Init prompt button to save the data */}
        {selectedTab === "InitPromp" && (
          <>
            <button
              type="button"
              className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              onClick={() => handleClickInit()}
            >
              Save
            </button>
          </>
        )}
      </div>
      <div className="w-full border-y-2 border-x-2 border-blue-200 h-auto rounded-lg">
        {/* Navigation for Init Promp and LLM Config */}
        <div
          className="top-0 left-1/2 transform -translate-x-1/2 bg-gray-200 text-black border border-black w-1/4 h-10 rounded-md relative flex justify-between items-center px-4 "
          style={{ clipPath: "polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%)" }}
        >
          <div
            className="w-1/2 h-full flex justify-center items-center cursor-pointer hover:bg-gray-400"
            onClick={() => setSelectedTab("LLMConfig")}
          >
            LLM Config
          </div>
          <div
            className="w-1/2 h-full flex justify-center items-center cursor-pointer hover:bg-gray-400"
            onClick={() => setSelectedTab("InitPromp")}
          >
            Init Promp
          </div>
        </div>


        {selectedTab === "LLMConfig" && (
          <>
            <LlmTable toolSchemas={toolSchemas} />
          </>
        )}

        {selectedTab === "InitPromp" && (
          <>
            <LlmInit />
          </>
        )}
      </div>
    </>
  );
};

export default LlmConfig;
