import React, { createContext, useContext, useState, ReactNode,
    Dispatch, SetStateAction
} from 'react';






export interface ImageFile {
    file: File;
    uint8Array: Uint8Array;
    height: number;
    width: number;
}



export interface PlaygroundData {
    query: {};
    output: {};
    toolSchema: {
        obj: Record<string, string>;
    };
    textract: {
       
    };
    images: ImageFile[];
}



interface PlaygroundContextProps {
    selectedTab: string;
    setSelectedTab: (tab: string) => void;
    tabCompletionState: Record<string, boolean>;
    setTabCompletionState: Dispatch<SetStateAction<{query:boolean, toolSchema:boolean,textract: boolean }>>;
    data: PlaygroundData;
    setData:  Dispatch<SetStateAction<PlaygroundData>>;
}

const PlaygroundContext = createContext<PlaygroundContextProps | undefined>(undefined);

export const PlaygroundProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [selectedTab, setSelectedTab] = useState<string>('textract');
    const [tabCompletionState, setTabCompletionState] = useState<{query:boolean, toolSchema:boolean,textract: boolean }>({
        query: false,
        toolSchema: false,
        textract: false,
    });
    const [data, setData] = useState<PlaygroundData>({
        query: {},
        output: {},
        toolSchema: {
            obj: {
                //userInputs: 'string',
            },
        },
        textract: {},
        images: [],
    });



    return (
        <PlaygroundContext.Provider
            value={{
                selectedTab,
                setSelectedTab,
                tabCompletionState,
                setTabCompletionState,
                data,
                setData,
            }}
        >
            {children}
        </PlaygroundContext.Provider>
    );
};

export const usePlaygroundContext = (): PlaygroundContextProps => {
    const context = useContext(PlaygroundContext);
    if (!context) {
        throw new Error('usePlaygroundContext must be used within a PlaygroundProvider');
    }
    return context;
};