// Import Firebase Storage methods
import { 
    ref, uploadBytesResumable, 
    getDownloadURL, deleteObject,
    getMetadata, UploadTaskSnapshot, 
    StorageReference } from 'firebase/storage';
import { storage } from '../../config/firebase'; // Import Firebase storage instance

/**
 * @class StorageManager
 * @description Class to manage Firebase Storage operations such as uploading, listening to progress, retrieving, and deleting files.
 */
export class StorageManager {

    // Singleton instance
    private static instance: StorageManager;

    /**
     * @method getInstance
     * @description Returns a singleton instance of StorageManager
     * @returns {StorageManager} A singleton instance of StorageManager
     */
    public static getInstance(): StorageManager {
        if (!StorageManager.instance) {
            StorageManager.instance = new StorageManager();
        }
        return StorageManager.instance;
    }

    //----------------------------------------------------------------
    // Upload File and Listen to Progress

    /**
     * @method uploadFile
     * @description Uploads a file to Firebase Storage and listens to the upload progress
     * @param filePath {string} Path where the file should be stored
     * @param file {File} The file object to be uploaded
     * @param onProgress {(progress: number) => void} Callback to monitor upload progress
     * @param onComplete {(downloadURL: string) => void} Callback when the upload is complete
     * @param onError {(error: Error) => void} Callback when an error occurs during upload
     * @returns {void}
     */
    public uploadFileAndListen(
        filePath: string, 
        file: Blob | Uint8Array | ArrayBuffer, 
        onProgress: (progress: number) => void, 
        onComplete: (downloadURL: string) => void, 
        onError: (error: Error) => void
    ): void {
        const fileRef = ref(storage, filePath); // Reference to the file path in Firebase Storage

        const uploadTask = uploadBytesResumable(fileRef, file);

        // Listen for state changes (progress, pause, resume)
        uploadTask.on('state_changed', 
            (snapshot: UploadTaskSnapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                onProgress(progress); // Call the progress callback
            }, 
            (error: Error) => {
                onError(error); // Handle error during upload
            }, 
            async () => {
                // Upload complete, get the download URL
                try {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    onComplete(downloadURL); // Call the completion callback
                } catch (error) {
                    // type the error
                    const err = error as Error;
                    onError(err);
                }
            }
        );
    }

    //----------------------------------------------------------------
    // Download File URL

    /**
     * @method uploadFile
     * @description Uploads a file to Firebase Storage and listens to the upload progress
     * @param filePath {string} Path where the file should be stored
     * @returns {Promise<string>} Resolves with the download URL
     */
    public async uploadFile(
        filePath: string, 
        file: Blob | Uint8Array | ArrayBuffer
    ): Promise<string> {
        try {
            const fileRef = ref(storage, filePath); // Reference to the file in Firebase Storage
            const upload = await uploadBytesResumable(fileRef, file);
            if (upload.state === 'success') {
                return filePath
            }
            return filePath;
        } catch (error) {
            console.error("Failed to get download URL:", error);
            throw error;
        }
    }


    //----------------------------------------------------------------
    // Download File URL

    /**
     * @method getDownloadURL
     * @description Retrieves the download URL for a file in Firebase Storage
     * @param filePath {string} The file path in Firebase Storage
     * @returns {Promise<string>} Resolves with the download URL
     */
    public async getDownloadURL(filePath: string): Promise<string> {
        try {
            const fileRef = ref(storage, filePath); // Reference to the file in Firebase Storage
            const downloadURL = await getDownloadURL(fileRef);
            return downloadURL;
        } catch (error) {
            console.error("Failed to get download URL:", error);
            throw error;
        }
    }

    //----------------------------------------------------------------
    // Delete File

    /**
     * @method deleteFile
     * @description Deletes a file from Firebase Storage
     * @param filePath {string} The file path in Firebase Storage
     * @returns {Promise<void>} Resolves when the file is successfully deleted
     */
    public async deleteFile(filePath: string): Promise<void> {
        try {
            const fileRef = ref(storage, filePath); // Reference to the file in Firebase Storage
            await deleteObject(fileRef);
            console.log(`File ${filePath} deleted successfully`);
        } catch (error) {
            console.error("Failed to delete file:", error);
            throw error;
        }
    }

    //----------------------------------------------------------------
    // Retrieve File Metadata

    /**
     * @method getFileMetadata
     * @description Retrieves metadata of a file in Firebase Storage
     * @param filePath {string} The file path in Firebase Storage
     * @returns {Promise<any>} Resolves with the file metadata
     */
    public async getFileMetadata(filePath: string): Promise<any> {
        try {
            const fileRef = ref(storage, filePath); // Reference to the file in Firebase Storage
            const metadata = await getMetadata(fileRef);
            return metadata;
        } catch (error) {
            console.error("Failed to get file metadata:", error);
            throw error;
        }
    }
}
