

import React, { 
    Dispatch,
    SetStateAction,
    useEffect, 
    useState 
} from "react";

import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'

import { jsPDF } from "jspdf";
import JSZip from "jszip";

// context
import { 
    usePlaygroundContext
} from './ui/PlaygroundContext';


import {
    Extractor
} from "../../../services/playground/textract/textract";

import { 
    TextractClient, 
    AnalyzeDocumentCommandOutput, ListAdaptersCommand,
    AnalyzeDocumentCommandInput, AnalyzeDocumentCommand, 
    BlockType, Block, DetectDocumentTextCommand, AnalyzeDocumentResponse
} from "@aws-sdk/client-textract"; // ES Modules import



const extractor = new Extractor();


export interface ImageFile {
    file: File;
    uint8Array: Uint8Array;
    height: number;
    width: number;
}


/**
 *  For this tab, the goal is to upload an image from the device or from a URL link. Once it's uploaded, 
 * it will be saved and sent to the Textract API. Using the module in services slash playground slash Textract, 
 * you can send that image. Once that image returns, the JSON is saved to a storage bucket, and so is the image.
 * 
 * Please create a button that gives you the option to upload an image from the device or a URL. 
 * Once an image is uploaded, hold that image in a buffer. 
 * Then, add a button that appears once the image has been uploaded called GetTextract. 
 * Once that's complete, display the Textract as a string. 
 * Then, once that's displayed, render another button that stores the image and the Textract to Firebase Storage Bucket.
 * 
 * Once all of this is complete, store the data in PlaygroundData and set the tab completion state to
 * 
 * @returns 
 */

export const TextractTab: React.FC = () => {

    const {
        setTabCompletionState, setData, setSelectedTab
    } = usePlaygroundContext();

    const [textractData, setTextractData] = useState<any>(null);
    // Define a state variable to store the selected image
    const [images, setImages] = useState<ImageFile[]>([]);


    const getMaxImageSize = () => {
        let maxWidth = 0;
        let maxHeight = 0;
        images.map((image) => {
            if (image.width > maxWidth) {
                maxWidth = image.width;
            }
            if (image.height > maxHeight) {
                maxHeight = image.height;
            }
        });
        return { maxWidth: maxWidth, maxHeight: maxHeight };
    }

    const generatePDF = async () => {
        const { maxWidth, maxHeight } = getMaxImageSize();
        const doc = new jsPDF({
            unit: "px",
            //format: [maxWidth, maxHeight],
        });

        images.map((image, index) => {

            doc.addPage(
                [image.width, image.height],

            );
            // add an image to the pdf
            doc.addImage(
                image.uint8Array,  // the image as a Uint8Array
                'JPEG',  // the image format   
                0, // X position (0 = left)
                0, // Y position (0 = top)
                image.width, // width (4 = 4 pixels)
                image.height, // height (2 = 2 pixels)

                `image-${index}`, // alias
                'FAST', // compression
                0, // rotation
            );

        })
       
        return doc.output("datauri", {
            filename: "converted_images.pdf"
        });//doc.save("converted_images.pdf");
        
    };

    const getTextract = async () => {
        if(images.length === 0 ) return;
        // Get the Textract data
        setData((prevState) => ({
            ...prevState,
            images: images,
        }));

        const textractTree = await extractor.getTextract(images[0].uint8Array);
        if (!textractTree) {
            alert('Error getting Textract textractTree');
        }

        setTextractData(textractTree);

        //console.log(textractTree);
    }

    const handleLocalTextract = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        // Get the textract.json file
        const textractFile = event.target.files[0];
        // Get the image file
        const imageFile = images[0].file;
        // Get the textract data from the textract.json file
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const textractJson = JSON.parse(reader.result as string);
                setTextractData(textractJson);
            }
        };
        reader.readAsText(textractFile);
        // Create a new ImageFile object
        const newImageFile: ImageFile = {
            file: imageFile,
            uint8Array: images[0].uint8Array,
            height: images[0].height,
            width: images[0].width
        }
        // Add the image to the images array
        setImages([newImageFile]);
    }

    const saveTextractFolder = async () => {
        // Save the image and Textract to local storage
        const fileName = 'textract-' + images[0].file.name.split('.')[0] + '.json'; 
        // save a json file with the textract data
        const textractJson = JSON.stringify(textractData);
        const textractBlob = new Blob([textractJson], { type: 'application/json' });
        const textractUrl = URL.createObjectURL(textractBlob);
        const textractLink = document.createElement('a');
    
        return new Promise((resolve, reject) => {
            textractLink.href = textractUrl;
            textractLink.download = fileName ;
            textractLink.click();
            resolve('success');
        });
    }

    // Set the tab completion state to true once all the requirements are met
    const handleSetCompletionState = () => {
        setTabCompletionState((prevState) => ({
            ...prevState,
            textract: true,
        }));
        setSelectedTab('toolSchema');
    }

    return (
        <div className="flex h-screen bg-gray-900 text-white">
            {/* Left side - ObjectComponent (form) */}
            <div className="w-1/3 p-6 bg-gray-800 h-full">
                <ImageUploader 
                    images={images}
                    setImages={setImages}
                    handleLocalTextract={handleLocalTextract}
                />

                <div
                    className="flex flex-col space-y-10"
                >
                    {textractData ? (
                        <button
                            className="w-half bg-green-500 hover:bg-green-700 text-white font-semibold mt-7 py-2 px-4 rounded-md"
                            type="button"
                            onClick={saveTextractFolder}
                        >
                            Save Images And Textract To Folder
                        </button>
                    ): (
                        <>
                            {images.length > 0 && (
                                <button
                                    className="w-half bg-green-600 hover:bg-green-700 text-white font-semibold mt-7 py-2 px-4 rounded-md"
                                    type="button"
                                    //onClick={()=>generatePDF()}
                                    onClick={()=>getTextract()}
                                >
                                    Get Textract
                                </button>
                            )}
                        </>
                    )}

                    {textractData && (
                        <button
                            className="w-half bg-red-600 hover:bg-green-700 text-white font-semibold mt-7 py-2 px-4 rounded-md"
                            type="button"
                            onClick={()=>handleSetCompletionState()}
                        >
                            Continue With Schema
                        </button>
                    )}

                </div>

            </div>


            {/* Right side - Scrollable schema object list */}
            <div className="w-3/4 p-6 overflow-y-auto">
                <h1 className="text-2xl font-bold mb-4 text-gray-100">Tool Schema</h1>
                <div className="space-y-4">
                    <ImageViewer
                        images={images}
                        textractData={textractData}
                        componentSize={{ width: 800, height: 800 }}
                    />
                    {textractData &&
                        <TextractViewer
                            textractObj={textractData}
                        />
                    }
                </div>
            </div>
        </div>
    );
}


interface ImageUploaderProps {
    images: ImageFile[]
    setImages: Dispatch<SetStateAction<ImageFile[]>>

    handleLocalTextract: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ 
    images, setImages, handleLocalTextract
}) => {
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        // initialize the images array
        // Convert each file to ArrayBuffer and then to Uint8Array
        Array.from(event.target.files!).map(file => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.result) {
                    // Create a new image object
                    const newImage = new Image();
                    // Get the ArrayBuffer from the reader result
                    const arrayBuffer = reader.result as ArrayBuffer;
                    // Set the source of the image to the file
                    newImage.src = URL.createObjectURL(file);
                    // Create a new ImageFile object
                    const imageFile: ImageFile = {
                        file: file,
                        uint8Array: new Uint8Array(arrayBuffer),
                        height: 0,
                        width: 0
                    }
                    // Get the height and width of the image
                    newImage.onload = () => {
                        //console.log("newImage: ",newImage.height, newImage.width);
                        imageFile.height = newImage.height;
                        imageFile.width = newImage.width;
                        
                    }
                    // Add the image to the images array
                    setImages((prevImages) => [...prevImages, imageFile]);
                }
            };
            return reader.readAsArrayBuffer(file);
        });
        //console.log("newImages: ", newImages);  
        //setImages((prevImages) => [...prevImages, ...newImages]);
    };


    return (
        <div className="p-4 bg-gray-700 rounded-lg shadow-lg space-y-6 flex-col">
            <h1 className="text-white">Upload An Image To Send To Textract</h1>
            
            {/* Custom styled button to trigger file input */}
            <label className="px-6 py-2 bg-blue-500 text-white rounded-lg cursor-pointer inline-block">
                Choose Images
                <input
                    type="file"
                    name="myImage"
                    accept="image/*"
                    multiple
                    className="hidden"
                    onChange={handleFileChange}
                />
            </label>
            
            {/* Custom styled button to trigger file input */}
            <label className="px-6 py-2 bg-gray-500 text-white rounded-lg cursor-pointer inline-block">
                Upload Local Textract
                <input
                    type="file"
                    name="myImage"
                    accept="image/*,.json"
                    multiple
                    className="hidden"
                    onChange={handleLocalTextract} // restore the saved image & textract
                    // upload textract.json & the corresponding image
                    // push the image to the images array
                    // set the textract data to the textract.json
                />
            </label>

            {/* Display the selected images */}
            <div className="flex flex-col space-y-4">
                {images.map((image, index) => (
                    <div key={index} className="flex items-center ">
                        <img
                            src={URL.createObjectURL(image.file)}
                            alt="selected"
                            className="w-12 h-12 object-contain rounded-lg"
                        />
                        <span className="text-white pl-4">{image.file.name}</span>
                        <button
                            className="pl-10"
                            onClick={() =>
                                setImages((prev) =>
                                    prev.filter((_, i) => i !== index)
                                )
                            }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 text-red-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}


const TextractViewer: React.FC<{textractObj: {}}> = ({textractObj}) => {
    return (
        <div className="bg-gray-800 p-4 rounded-lg shadow-m">
            <JsonView src={textractObj} />
        </div>
    );
}


const ImageViewer: React.FC<{
    images: ImageFile[], textractData: AnalyzeDocumentResponse["Blocks"],
    componentSize: { width: number, height: number }
}> = ({images, textractData, componentSize}) => {

    const [ zoom, setZoom ] = useState(1);
    const [ keyValueState, setKeyValueState ] = useState<{
        [key: string]: string
    }>({});

    const onTextChange = (key: string, value: string) => {
        setKeyValueState((prevState) => ({
            ...prevState,
            [key]: value
        }));
    }

    // build relative to the component size
    const aspectRatio = images[0]?.width / images[0]?.height;
    const resizedWidth = componentSize.width;
    const resizedHeight = resizedWidth / aspectRatio;

    const handleFocus = (userText: string, userKey: string) => {
        setZoom(1.5);
        if (!userText || !userKey) return;
        setKeyValueState((prevState) => ({
            ...prevState,
            [userKey]: userText
        }));

        console.log("userText: ", userText, "userKey: ", userKey);
    }




    return (
        <div style={{
            transform: `scale(${zoom})`,
        }}>
            {textractData && (
                <div style={{position: "absolute",
                   
                }}>
                    {textractData?.map((block: Block, index: number) => {
                        if (!block || !block.BlockType || !block.Geometry || !block.Geometry.BoundingBox) return null;
                        //const isKVS = block.BlockType === "KEY_VALUE_SET";
                        const isKVS = block.BlockType === "KEY_VALUE_SET";
                        let isValue = false


                        if (isKVS) {
                            // get the relationships of the key value set
                            const relationships = getRelationships(block, textractData);

                            // if there are relationships, then we can draw a box around the key value set
                            if(relationships.length > 0) {
                                // get the child block, which is the value and NOT the WORD
                                const childBlock = relationships.find((relationship) => relationship.BlockType !== "WORD");
                                const childWordBlock = relationships.find((relationship) => relationship.BlockType === "WORD");
                               
                                if (!childBlock || !childWordBlock) return null;
                                if (!childBlock.Geometry || !childBlock.Geometry.BoundingBox) return null;

                                isValue = childBlock?.EntityTypes![0] === "VALUE";
                                let userText: string = "";
                                let userKey: string = "";
                                if (isValue && childBlock.Relationships) {
                                    const valueRelationshipId = childBlock.Relationships![0].Ids![0];
                                    const valueWordBlock = searchByID(valueRelationshipId, textractData)!;
                                    const isSelection = valueWordBlock!.BlockType! === "SELECTION_ELEMENT";
                                    if (!valueWordBlock || isSelection) {return null};
                                    userText = valueWordBlock.Text!;
                                    userKey = childWordBlock.Text!;
                                    //onTextChange(valueWordBlock.Id!, userText);
                                }

                                const childTop = (childBlock!.Geometry!.BoundingBox!.Top!  * resizedHeight);
                                const childLeft = (childBlock!.Geometry!.BoundingBox!.Left! * resizedWidth);
                                const childWidth = (childBlock!.Geometry!.BoundingBox!.Width! * resizedWidth);
                                const childHeight = (childBlock!.Geometry!.BoundingBox!.Height! * resizedHeight);

                                // get the word block
                                const placeHolder = childWidth > childWordBlock!.Text!.length*100/2 ? "" : childWordBlock?.Text;
                                //console.log("placeHolder: ", placeHolder);

                                const isSaved = keyValueState[userKey]
                                let borderColor = userText.length > 0 ? (isSaved ? "2px solid green" : "2px solid yellow") : "2px solid red";
                       

                                return (
                                    <input 
                                        key={index} 
                                        placeholder={userText ? "" : placeHolder}
                                        //value={keyValueState[childWordBlock?.Text!]}
                                        value={keyValueState[userKey] || userText}
                                        style={{
                                            top: childTop,
                                            left: childLeft,
                                            width: childWidth,
                                            height: childHeight,
                                            border: borderColor,
                                            position: "absolute",
                                            backgroundColor: userText ? "" : "transparent",
                                            color: "black",
                                            padding: "0px",
                                            borderRadius: "0px",
                                        }}
                                        onFocus={() => {
                                            setTimeout(() => {
                                                handleFocus(userText, userKey);
                                            }, 500);
                                            
                                        }}
                                        onBlur={() => setZoom(1)}
                                    >
                                    </input>
                                );
                            }
                        }


                    })}
                </div>
            )}
            <img  
                src={images[0] ? URL.createObjectURL(images[0].file) : ""}
                alt="selected"
                width={resizedWidth || 0}
                height={resizedHeight || 0}
            />
        </div>
    )

}


const getRelationships = (block: Block, blocks: Block[]) => {
    const relationships: Block[] = [];
    
    // Check if the block has relationships
    if (block.Relationships) {
        block.Relationships.forEach((relationship) => {
            // For each relationship, find the child block by its ID
            relationship.Ids?.forEach((id) => {
                const childBlock = searchByID(id, blocks);
                
                if (childBlock) {
                    relationships.push(childBlock);

                    // Check if the child block is a KEY_VALUE_SET and recursively get its children
                    if (childBlock.BlockType === "KEY_VALUE_SET") {
                        const keyValueRelationships = getRelationships(childBlock, blocks);
                        relationships.push(...keyValueRelationships);
                    }
                }
            });
        });
    }
    
    return relationships;
};

const searchByID = (id: string, blocks: Block[]) => {
    for (const block of blocks) {
        if (block.Id === id) {
            return block;
        }
    }
}
