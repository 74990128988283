// src/pages/HomePage.tsx
import React, {
    useEffect,
    useState
} from 'react';
import { Link } from 'react-router-dom';


import { 
    OutputTab 
} from '../interface/components/playground/OutputTab';
import { 
    QueryTab 
} from '../interface/components/playground/QueryTab';
import { 
    TextractTab 
} from '../interface/components/playground/TextractTab';
import { 
    ToolSchemaTab 
} from '../interface/components/playground/ToolSchemaTab';

// context
import { 
    usePlaygroundContext
} from '../interface/components/playground/ui/PlaygroundContext';




export default function Playground(){

    const {  selectedTab, setSelectedTab } = usePlaygroundContext();


    return (
        <div className="container">
            <UiTab/>

            {selectedTab === 'textract' && <TextractTab />}
            {selectedTab === 'toolSchema' && <ToolSchemaTab />}
            {selectedTab === 'query' && <QueryTab />}
            {selectedTab === 'output' && <OutputTab />}

        </div>
    );
};

const UiTab: React.FC = () => {
    // It will use to Toggle between the subscriton general and llm
    const {selectedTab, setSelectedTab, tabCompletionState} = usePlaygroundContext();
    return (
        <div className='flex items-center justify-center'>
            <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
                <ul
                    className="flex flex-wrap -mb-px text-sm font-medium text-center"
                    id="default-tab"
                    data-tabs-toggle="#default-tab-content"
                    role="tablist"
                >

                <li className="me-2" role="presentation">
                    <button
                        className={`inline-block p-4 ${selectedTab === 'textract'? 'border-b-2':''} rounded-t-lg`}
                        id="profile-tab"
                        data-tabs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => setSelectedTab('textract')}
                    >
                        Textract {tabCompletionState.textract && (<span>✅</span>)}
                    </button>
                </li>

                <li className="me-2" role="presentation">
                    <button
                        className={`inline-block p-4 ${selectedTab === 'toolSchema'? 'border-b-2':''} rounded-t-lg`}
                        id="profile-tab"
                        data-tabs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => setSelectedTab('toolSchema')}
                    >
                        ToolSchema {tabCompletionState.toolSchema && (<span>✅</span>)}
                    </button>
                </li>

                <li className="me-2" role="presentation">
                    <button
                        className={`inline-block p-4 ${selectedTab === 'query'? 'border-b-2':''} rounded-t-lg`}
                        id="profile-tab"
                        data-tabs-target="#profile"
                        type="button"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                        onClick={() => setSelectedTab('query')}
                    >
                        Query {tabCompletionState.query && (<span>✅</span>)}
                        
                    </button>
                    
                </li>

                

                {!Object.keys(tabCompletionState).find(key => !tabCompletionState[key]) && (
                    <li className="me-2" role="presentation">
                        <button
                            className={`inline-block p-4 ${selectedTab === 'output'? 'border-b-2':''} rounded-t-lg`}
                            id="profile-tab"
                            data-tabs-target="#profile"
                            type="button"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                            onClick={() => setSelectedTab('output')}
                        >
                            Output
                        </button>
                    </li>
                )}
            
                </ul>
            </div>
    
        </div>
        
    );
  };