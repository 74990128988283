// Import Firebase configuration
import { db } from '../../config/firebase'; 

// Import Firestore methods
import { 
    collection, doc, 
    getDocs, getDoc, 
    setDoc, addDoc, 
    deleteDoc, updateDoc, 
    query, where, 
    runTransaction, writeBatch,
    DocumentReference, CollectionReference
} from 'firebase/firestore'; 

// Import module types
import { Subscription } from '../../../types/config';

/**
 * @class FirestoreManager
 * @description Singleton Class Manages Firestore database operations including nested collections and fields
 */
export class FirestoreManager {

    // Singleton instance
    private static instance: FirestoreManager;

    // Firestore collection name
    private collectionName: string;

    /**
     * @constructor
     * @param collectionName {string} The name of the Firestore collection
     */
    private constructor(collectionName: string) {
        this.collectionName = collectionName;
    }

    /**
     * @method getInstance
     * @description Returns a singleton instance of FirestoreManager
     * @param collectionName {string} The name of the Firestore collection
     * @returns {FirestoreManager} A singleton instance of FirestoreManager
     */
    public static getInstance(collectionName: string): FirestoreManager {
        if (!FirestoreManager.instance) {
            try {
                FirestoreManager.instance = new FirestoreManager(collectionName);
            } catch (error) {
                console.error("Failed to initialize FirestoreManager:", error);
                throw error;
            }
        } else {
            FirestoreManager.instance.collectionName = collectionName; // Update collection if instance already exists
        }
        return FirestoreManager.instance;
    }

    //----------------------------------------------------------------
    // Lower-level Firestore operations (CRUD)

    /**
     * @method addDocument
     * @description Adds a new document to the Firestore collection
     * @param data {object} Data object to be added
     * @param docId {string} Optional document ID to assign to the new document
     * @returns {Promise<string>} The document ID of the new document
     */
    public async addDocument(data: any, docId?: string): Promise<string> {
        try {
            const docRef = docId 
                ? doc(db, this.collectionName, docId)
                : doc(collection(db, this.collectionName));

            await setDoc(docRef, data);
            return docRef.id;
        } catch (error) {
            console.error("Failed to add document:", error);
            throw error;
        }
    }

    /**
     * @method getDocument
     * @description Retrieves a document by its ID
     * @param docId {string} The document ID
     * @returns {Promise<any>} The document data
     */
    public async getDocument(docId: string): Promise<any> {
        try {
            const docRef = doc(db, this.collectionName, docId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                return docSnap.data();
            } else {
                console.error("No document found with the given ID.");
                return null;
            }
        } catch (error) {
            console.error("Failed to get document:", error);
            throw error;
        }
    }

    /**
     * @method getAllDocuments
     * @description Retrieves all documents from the Firestore collection
     * @returns {Promise<any[]>} An array of document data
     */
    public async getAllDocuments(): Promise<any[]> {
        try {
            const querySnapshot = await getDocs(collection(db, this.collectionName));
            return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        } catch (error) {
            console.error("Failed to get all documents:", error);
            throw error;
        }
    }

    /**
     * @method updateDocument
     * @description Updates an existing document by its ID
     * @param docId {string} The document ID
     * @param data {object} Updated data object
     * @returns {Promise<void>} Resolves on success
     */
    public async updateDocument(docId: string, data: any): Promise<void> {
        try {
            const docRef = doc(db, this.collectionName, docId);
            await updateDoc(docRef, data);
        } catch (error) {
            console.error("Failed to update document:", error);
            throw error;
        }
    }

    /**
     * @method deleteDocument
     * @description Deletes a document by its ID
     * @param docId {string} The document ID
     * @returns {Promise<void>} Resolves on success
     */
    public async deleteDocument(docId: string): Promise<void> {
        try {
            const docRef = doc(db, this.collectionName, docId);
            await deleteDoc(docRef);
        } catch (error) {
            console.error("Failed to delete document:", error);
            throw error;
        }
    }

    //----------------------------------------------------------------
    // Nested Collection Operations

    /**
     * @method getNestedCollection
     * @description Retrieves all documents from a nested collection
     * @param docId {string} The parent document ID
     * @param nestedCollectionName {string} The nested collection name
     * @returns {Promise<any[]>} An array of document data from the nested collection
     */
    public async getNestedCollection(docId: string, nestedCollectionName: string): Promise<any[]> {
        try {
            const nestedCollectionRef = collection(db, this.collectionName, docId, nestedCollectionName);
            const querySnapshot = await getDocs(nestedCollectionRef);
            return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        } catch (error) {
            console.error(`Failed to get nested collection ${nestedCollectionName}:`, error);
            throw error;
        }
    }

    /**
     * @method addNestedDocument
     * @description Adds a new document to a nested collection
     * @param docId {string} The parent document ID
     * @param nestedCollectionName {string} The nested collection name
     * @param data {object} Data object to be added
     * @returns {Promise<string>} The document ID of the new nested document
     */
    public async addNestedDocument(docId: string, nestedCollectionName: string, data: any): Promise<string> {
        try {
            const nestedCollectionRef = collection(db, this.collectionName, docId, nestedCollectionName);
            const docRef = await addDoc(nestedCollectionRef, data);
            return docRef.id;
        } catch (error) {
            console.error(`Failed to add document to nested collection ${nestedCollectionName}:`, error);
            throw error;
        }
    }

    //----------------------------------------------------------------
    // Handling Nested Fields (Dot Notation)

    /**
     * @method updateNestedField
     * @description Updates a nested field within a document using dot notation
     * @param docId {string} The document ID
     * @param fieldPath {string} The field path in dot notation (e.g., 'address.street')
     * @param value {any} The new value to set
     * @returns {Promise<void>} Resolves on success
     */
    public async updateNestedField(docId: string, fieldPath: string, value: any): Promise<void> {
        try {
            const docRef = doc(db, this.collectionName, docId);
            await updateDoc(docRef, { [fieldPath]: value });
        } catch (error) {
            console.error(`Failed to update nested field ${fieldPath}:`, error);
            throw error;
        }
    }

    //----------------------------------------------------------------
    // Batch Operations

    /**
     * @method performBatchOperations
     * @description Executes multiple operations in a batch (atomicity guaranteed)
     * @param operations {Function} Callback containing batch operations
     * @returns {Promise<void>} Resolves on success
     */
    public async performBatchOperations(operations: (batch: any) => void): Promise<void> {
        const batch = writeBatch(db);
        try {
            operations(batch);
            await batch.commit();
        } catch (error) {
            console.error("Failed to perform batch operations:", error);
            throw error;
        }
    }

    //----------------------------------------------------------------
    // Transaction Operations

    /**
     * @method runTransactionOperation
     * @description Runs a Firestore transaction for atomic operations
     * @param transactionFn {Function} Callback containing the transaction logic
     * @returns {Promise<void>} Resolves on success
     */
    public async runTransactionOperation(transactionFn: (transaction: any) => Promise<void>): Promise<void> {
        try {
            await runTransaction(db, async (transaction) => {
                await transactionFn(transaction);
            });
        } catch (error) {
            console.error("Failed to run transaction:", error);
            throw error;
        }
    }
}
