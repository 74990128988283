import React, { useEffect, useState } from 'react';
import { MissingInfoSchema } from '../../../../types/config';
import { useConfig } from '../../../contexts/ConfigContext';









const LlmTable: React.FC<{ toolSchemas: MissingInfoSchema[] | null }> = ({ toolSchemas }) => {
  
  // State to track which section of the accordion is open
  const [openSection, setOpenSection] = useState<number | null>(null);

  // Access configuration context, including disable button state and functions
  const { dissableButton, setDisableButton } = useConfig();

  // Access state and state setter for input values from the configuration context to acess the new value
  const { inputValues, setInputValues } = useConfig();
  
  // On mount or whenever toolSchemas change, initialize inputValues with toolSchemas
  useEffect(() => {
    setInputValues(toolSchemas);
  }, [toolSchemas]);

  // Check if toolSchemas are deeply equal to inputValues
  const deepEqual = (arr1: MissingInfoSchema[] | null, arr2: MissingInfoSchema[] | null) => {
    if (arr1 === arr2) return true; // If both references are the same
    if (!arr1 || !arr2 || arr1.length !== arr2.length) return false; // If null or different lengths
    return arr1.every((item, index) => 
      JSON.stringify(item) === JSON.stringify(arr2[index]) // Compare each item as JSON
    );
  };
  
  // UseEffect to enable or disable the button based on the comparison of toolSchemas and inputValues
  useEffect(() => {
    if (deepEqual(toolSchemas, inputValues)) {
      setDisableButton(true); // Disable button if data matches
    } else {
      setDisableButton(false); // Enable button if data differs
    }
  }, [inputValues]);

  // Function to toggle the accordion sections open or closed
  const toggleSection = (section: number) => {
    setOpenSection(openSection === section ? null : section); // Toggle between open and closed
  };

  // Handle changes to input fields and update the inputValues state
  const handleInputChange = (index: number, field: keyof MissingInfoSchema, value: string) => {
    setInputValues((prev: MissingInfoSchema[]) => 
      prev?.map((item, i) => 
        i === index ? { ...item, [field]: value } : item // Update the relevant field in the specified item
      ) || []
    );
  };

  return (
    <div className='mt-10'>
      {/* Accordion item for user input */}
      <h2 id="accordion-collapse-heading-1">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 rounded-t-xl focus:ring-4 focus:ring-gray-800 border-gray-700 text-gray-400 hover:bg-gray-800 gap-3"
          onClick={() => toggleSection(1)}
          aria-expanded={openSection === 1}
          aria-controls="accordion-collapse-body-1"
        >
          <span>User Input</span>
          <svg
            className={`w-3 h-3 ${openSection === 1 ? 'rotate-180' : ''} shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5L5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-1"
        className={`${openSection === 1 ? '' : 'hidden'}`}
        aria-labelledby="accordion-collapse-heading-1"
      >
        <div className="p-5 border border-b-0 border-gray-700 bg-gray-900">
          {/* Textarea for user input */}
          <textarea
            value={inputValues?.[0]?.userInput || ''} // Bind value to the inputValues state
            onChange={(e) => {
              handleInputChange(0, 'userInput', e.target.value); // Handle change event
            }}
            className="w-full p-2 border text-black border-gray-300 rounded resize-none"
            placeholder="Enter user input"
            rows={5} 
          />
        </div>
      </div>

      {/* Accordion item for missing fields */}
      <h2 id="accordion-collapse-heading-2">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 focus:ring-4 focus:ring-gray-800 border-gray-700 text-gray-400 hover:bg-gray-800 gap-3"
          onClick={() => toggleSection(2)}
          aria-expanded={openSection === 2}
          aria-controls="accordion-collapse-body-2"
        >
          <span>Missing Fields</span>
          <svg
            className={`w-3 h-3 ${openSection === 2 ? 'rotate-180' : ''} shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5L5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-2"
        className={`${openSection === 2 ? '' : 'hidden'}`}
        aria-labelledby="accordion-collapse-heading-2"
      >
        <div className="p-5 border border-b-0 border-gray-700">
          {/* Textarea for missing fields */}
          <textarea
            value={inputValues?.[0]?.missingFields || ''}
            onChange={(e) => {
              handleInputChange(0, 'missingFields', e.target.value); // Handle change event
            }}
            className="w-full p-2 border text-black border-gray-300 rounded resize-none"
            placeholder="Enter missing fields"
            rows={5}
          />
        </div>
      </div>

      {/* Accordion item for assistant all info request */}
      <h2 id="accordion-collapse-heading-3">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border focus:ring-4 focus:ring-gray-800 border-gray-700 text-gray-400 hover:bg-gray-800 gap-3"
          onClick={() => toggleSection(3)}
          aria-expanded={openSection === 3}
          aria-controls="accordion-collapse-body-3"
        >
          <span>Assistant Info Request</span>
          <svg
            className={`w-3 h-3 ${openSection === 3 ? 'rotate-180' : ''} shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5L5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-3"
        className={`${openSection === 3 ? '' : 'hidden'}`}
        aria-labelledby="accordion-collapse-heading-3"
      >
        <div className="p-5 border border-t-0 border-gray-700">
          {/* Textarea for assistant info request */}
          <textarea
            value={inputValues?.[0]?.assistantAllInfoRequest || ''}
            onChange={(e) => {
              handleInputChange(0, 'assistantAllInfoRequest', e.target.value); // Handle change event
            }}
            className="w-full p-2 border text-black border-gray-300 rounded resize-none"
            placeholder="Enter assistant info request"
            rows={5}
          />
        </div>
      </div>

      {/* Accordion item for plain text response */}
      <h2 id="accordion-collapse-heading-4">
        <button
          type="button"
          className="flex items-center justify-between w-full p-5 font-medium rtl:text-right border border-b-0 focus:ring-4 focus:ring-gray-800 border-gray-700 text-gray-400 hover:bg-gray-800 gap-3"
          onClick={() => toggleSection(4)}
          aria-expanded={openSection === 4}
          aria-controls="accordion-collapse-body-4"
        >
          <span>Plain Text Response</span>
          <svg
            className={`w-3 h-3 ${openSection === 4 ? 'rotate-180' : ''} shrink-0`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5L5 1 1 5"
            />
          </svg>
        </button>
      </h2>
      <div
        id="accordion-collapse-body-4"
        className={`${openSection === 4 ? '' : 'hidden'}`}
        aria-labelledby="accordion-collapse-heading-4"
      >
        <div className="p-5 border border-t-0 border-gray-700">
          {/* Textarea for plain text response */}
          <textarea
            value={inputValues?.[0]?.plainTextResponse || ''}
            onChange={(e) => {
              handleInputChange(0, 'plainTextResponse', e.target.value); // Handle change event
            }}
            className="w-full p-2 border text-black border-gray-300 rounded resize-none"
            placeholder="Enter plain text response"
            rows={5}
          />
        </div>
      </div>
    </div>
  );
};

export default LlmTable;
