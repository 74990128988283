
import React from 'react';
import { useConfig } from '../../../contexts/ConfigContext';

const UiTab: React.FC = () => {
  // It will use to Toggle between the subscriton general and llm
    const {selectedTab, setSelectedTab} = useConfig()
    return (
        <div className='flex items-center justify-center'>
        <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="default-tab"
            data-tabs-toggle="#default-tab-content"
            role="tablist"
          >
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 ${selectedTab === 'subscriptions'? 'border-b-2':''} rounded-t-lg`}
                id="profile-tab"
                data-tabs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                onClick={() => setSelectedTab('subscriptions')}
              >
                Subscription Plan
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`inline-block p-4 ${selectedTab === 'llm'? 'border-b-2':''} rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`}
                id="dashboard-tab"
                data-tabs-target="#dashboard"
                type="button"
                role="tab"
                aria-controls="dashboard"
                aria-selected="false"
                onClick={() => setSelectedTab('llm')}
              >
                LLM Config 
              </button>
            </li>
            <li className="me-2" role="presentation">
              <button
                className={`inline-block ${selectedTab === 'general'? 'border-b-2':''} p-4 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300`}
                id="settings-tab"
                data-tabs-target="#settings"
                type="button"
                role="tab"
                aria-controls="settings"
                aria-selected="false"
                onClick={() => setSelectedTab('general')}
              >
                General Config
              </button>
            </li>
         
          </ul>
        </div>
  
      </div>
      
    );
};

export default UiTab;
