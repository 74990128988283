import React, { useState } from 'react'
import { useConfig } from '../../contexts/ConfigContext';
// deleteAvailablePlan It will delte the available plan 
import { deleteAvailablePlan } from '../../../services/firebase';
// types for Subscription plan
import { Subscription } from '../../../types/config';
// Loading ui
import LoadingSpinner from '../common/LoadingSpinner';


const SubscriptionPlan: React.FC = () => {
  // setSubscriptionModel for toggling model
  // will give available plan
  // setReRender is used just for reRendeing
  // setEditSubscribtionPlan is setting the plan
  const { setSubscriptionModel,available,reRender,setReRender,setEditSubscribtionPlan} = useConfig()
// It will open model
  const handleOpenSubsPlan = () => {
    setSubscriptionModel(true)
    
  }
//  It is used for delte the available plan
  const handleRemoveAvailable = async (id: string) => {
    try {
      await deleteAvailablePlan(id); // Await the deletion process
      setReRender(!reRender)
      
      // Optionally, add any additional logic to update the UI or state
    } catch (error) {
      // Optionally, show an error message to the user
    }
  };
  // This is used for Edit the available Plan
  const handleEditAvailable = (subscribtion:Subscription) => {
      setSubscriptionModel(true);
      setEditSubscribtionPlan(subscribtion)

  }
  return (
    <>
   <section className="bg-white dark:bg-gray-900 rounded-lg">
  <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
    <div className='flex w-full items-center justify-between'>
    <span
  className="mb-4 text-2xl tracking-tight font-extrabold text-gray-900 dark:text-white "
  >
  Available Plan 
</span>
<button
  type="button"
  className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
  onClick={handleOpenSubsPlan}
>
  Add Subscription Plan
</button>
    </div>
    
    <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
      <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
      Subscription Plan
      </h2>
      <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
      The plan below shows all the available plans. 
      </p>
    </div>
    <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
      {/* Pricing Card */}
      {available.length === 0 ? <LoadingSpinner /> : available.map((item) => (
        <div key={item.id}> 
<h2
  className='text-2xl text-white'
>
  <strong>
    {item.name}
    </strong>
</h2>

        
         <div className="relative flex flex-col p-6 mx-auto max-w-lg  text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
          
         >
      <button className="absolute inline-flex items-center justify-center w-8 h-8 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-3 dark:border-gray-900 group"
      onClick={() => handleRemoveAvailable(item.id)}
      >
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>
<div className="absolute hidden group-hover:block  -top-14 right-0 mt-2 px-2 py-1 text-xs text-white bg-black rounded-lg">
      This will only delete the availablePlan
    </div>

  </button>
  {/* left edited plan */}
  <button
  className="absolute inline-flex items-center justify-center w-8 h-8 text-xs font-bold text-white bg-blue-500 border-2 border-white rounded-full -top-2 -left-3 dark:border-gray-900 group"
  onClick={() => handleEditAvailable(item)}
>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
</svg>

  <div className="absolute hidden group-hover:block -top-6 -left-4 mt-2 px-2 py-1 text-xs text-white bg-black rounded-lg">
    Edit 
  </div>
</button>

        <span className="mb-4 text-l font-semibold text-left">Id:
          <span className='text-gray-400 ml-2'>
              {item.id}
            </span>
        </span>
        <span className="mb-4 text-l font-semibold text-left">Name:
        <span className='text-gray-400 ml-2'>
              {item.name}
            </span>
        </span>

        <span className="mb-4 text-l font-semibold text-left">description: </span>
        <p
  className="font-light text-left text-gray-500 sm:text-lg dark:text-gray-400 border border-gray-300 rounded-lg p-4 bg-white shadow-md dark:bg-gray-800 h-24 overflow-y-scroll"
>
  {item.description}
</p>

<span className="mb-4 text-l text-left font-semibold">Price:
<span className='text-gray-400 ml-2'>
              {item.price}
            </span>  </span>
<span className="mb-4 text-l text-left font-semibold">Currency:
<span className='text-gray-400 ml-2'>
              {item.currency}
            </span>  </span>
<span className="mb-4 text-l text-left font-semibold">BillingCycle:
<span className='text-gray-400 ml-2'>
              {item.billingCycle}
            </span>  </span>


        {/* List */}
        <ul role="list" className="mb-8 space-y-4 text-left">
        <span className="mb-4 text-l font-semibold">Feature:</span>

        {item.features.map((feature, index) => (
              <li className="flex items-center space-x-3 text-gray-400">
              {/* Icon */}
              <svg
                className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              <span>       
                   {feature}
              </span>
            </li>
))}
      
 
        </ul>
   
      </div>
      </div>

      ))}
      
     
 
    </div>
  </div>
</section>

    </>
  )
}
export default SubscriptionPlan;
