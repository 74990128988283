import React, { 
    useEffect, 
    useState 
} from "react";



export const QueryTab: React.FC = () => {

    return (
        <div className="">
            <h1>QueryTab Tab</h1>
        </div>
    );
}