import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Subscription,MissingInfoSchema, InitChat } from '../../types/config'; // Make sure this path is correct

interface ConfigContextType {
  selectedTab: 'subscriptions' | 'llm' | 'general';//there taps to toggle between
  setSelectedTab: (tab: 'subscriptions' | 'llm' | 'general') => void;
  subscriptionModel: boolean;//toggle between model
  setSubscriptionModel: (value: boolean) => void;
  reRender: boolean;//just used for reRendering when add edit and delte
  setReRender: (value: boolean) => void;
  available: Subscription[];//store all the available plan
  setAvailable: (plans: Subscription[]) => void;
  editsubscribtionPlan: Subscription | null; // Initialize as null or provide a default and to store EditSubsPlan
  setEditSubscribtionPlan: (plan: Subscription | null) => void; // Add setter function
  openDeleteDialog:boolean;//open the dialog of delte box
  setOpenDeleteDialog: (value:boolean) => void
  openLlmModel:boolean;//
  setOpenLlmModel:(value:boolean) => void;
  dissableButton: boolean;
  setDisableButton:(value:boolean) => void;
  inputValues: MissingInfoSchema[] | null; // Add inputValues here
  setInputValues: (value:any) => void; 
  dataInitChat: InitChat | null; // Data for InitChat
  setDatainitChat: (value: InitChat | null) => void;

}

const ConfigContext = createContext<ConfigContextType>({
  selectedTab: 'subscriptions',
  setSelectedTab: () => {},
  subscriptionModel: false,
  setSubscriptionModel: () => {},
  reRender: false,
  setReRender: () => {},
  available: [],
  setAvailable: () => {},
  editsubscribtionPlan: null, // Initialize as null
  setEditSubscribtionPlan: () => {}, // Add default setter
  openDeleteDialog:false,
  setOpenDeleteDialog: () => {},
  openLlmModel:false,
  setOpenLlmModel:() => {},
  dissableButton:false,
  setDisableButton:() => {},
  inputValues: null, // Default inputValues
  setInputValues: () => {} ,
  dataInitChat:null,
  setDatainitChat:() => {}


});

export function ConfigProvider({ children }: { children: ReactNode }) {
  const [selectedTab, setSelectedTab] = useState<'subscriptions' | 'llm' | 'general'>('subscriptions');
  const [subscriptionModel, setSubscriptionModel] = useState(false);
  const [reRender, setReRender] = useState<boolean>(false);
  const [available, setAvailable] = useState<Subscription[]>([]);
  const [editsubscribtionPlan, setEditSubscribtionPlan] = useState<Subscription | null>(null); // Initialize as null
  const [openDeleteDialog,setOpenDeleteDialog] = useState(false)
  const [openLlmModel,setOpenLlmModel] = useState(false)
  const [dissableButton,setDisableButton] = useState(false) 
  const [inputValues, setInputValues] = useState<MissingInfoSchema[] | null>(null); // Initialize inputValues
  const [dataInitChat,setDatainitChat] = useState<InitChat | null >(null)
  return (
    <ConfigContext.Provider 
      value={{ 
        selectedTab, 
        setSelectedTab, 
        subscriptionModel, 
        setSubscriptionModel, 
        reRender, 
        setReRender, 
        available, 
        setAvailable, 
        editsubscribtionPlan, 
        setEditSubscribtionPlan,
        openDeleteDialog,
        setOpenDeleteDialog,
        openLlmModel,
        setOpenLlmModel,
        dissableButton,
        setDisableButton,
        inputValues, 
        setInputValues ,
        dataInitChat,
        setDatainitChat
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export const useConfig = () => useContext(ConfigContext);
