import React, { 
    useEffect, 
    useState 
} from "react";

// context
import { 
    usePlaygroundContext
} from './ui/PlaygroundContext';

/**
 * For the Tool Schema tab, just use one of the components you already created. 
 * These tool schemas are going to be completely different, so just create a basic component where you can build an object.
 * @returns 
 */
export const ToolSchemaTab: React.FC = () => {
    const { data, setData, setTabCompletionState, tabCompletionState, setSelectedTab } = usePlaygroundContext();

    const handleTabCompletion = ( state: boolean) => {
        // Set the tab completion state to true once all the requirements are met
        setTabCompletionState((prevState) => ({
            ...prevState,
            toolSchema: state,
        }));
        if(state) setSelectedTab('query');
        if(!state) {
            // clear the data
            setSelectedTab('toolSchema');
            setData((prevState) => ({
                ...prevState,
                toolSchema: {
                    obj: {},
                },
            }));
        }
    }

    return (
    <div className="flex h-screen bg-gray-900 text-white">
        {/* Left side - ObjectComponent (form) */}
        <div className="w-1/3 p-6 bg-gray-800 h-full">
            <ObjectComponent />
            {tabCompletionState.toolSchema ? (
                <button
                    className="w-half bg-red-600 hover:bg-green-700 text-white font-semibold mt-7 py-2 px-4 rounded-md"
                    type="button"
                    onClick={()=>handleTabCompletion(false)}
                >
                    Clear Completion
                </button>
            ): (
                <button
                    className="w-half bg-green-600 hover:bg-green-700 text-white font-semibold mt-7 py-2 px-4 rounded-md"
                    type="button"
                    onClick={()=>handleTabCompletion(true)}
                >
                    Continue With Schema
                </button>
            )}
        </div>


        {/* Right side - Scrollable schema object list */}
        <div className="w-2/3 p-6 overflow-y-auto">
            <h1 className="text-2xl font-bold mb-4 text-gray-100">Tool Schema</h1>
            <div className="space-y-4">
                {data.toolSchema.obj &&
                Object.keys(data.toolSchema.obj).map((key, index) => {
                    return (
                        <KeyValueComponent
                            key={index}
                            schemaKey={key}
                            value={data.toolSchema.obj[key]}
                        />
                    );
                })}
            </div>
        </div>
    </div>
    );
};
// Component to render individual key-value pair
interface KeyValueComponentProps {
    schemaKey: string;
    value: string;
}

const KeyValueComponent: React.FC<KeyValueComponentProps> = ({
    schemaKey,
    value,
}) => {
    const { data, setData } = usePlaygroundContext();
    const [valText, setValText] = useState<string>(value);

    // Update the value in context when changed
    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValText(e.target.value);
    setData((prevData) => ({
        ...prevData,
        toolSchema: {
        ...prevData.toolSchema,
        obj: {
            ...prevData.toolSchema.obj,
            [schemaKey]: e.target.value,
        },
        },
    }));
    };

    return (
    <div className="bg-gray-800 p-4 rounded-lg shadow-md">
        <p className="text-lg text-left font-bold text-gray-200 mb-4">{schemaKey}:</p>
        <input
            className="w-full bg-gray-700 border border-gray-500 rounded-md p-2 text-gray-100"
            type="text"
            value={valText}
            onChange={handleValueChange}
            placeholder="Enter value"
        />
    </div>
    );
};
  
const ObjectComponent: React.FC = () => {
    const { data, setData } = usePlaygroundContext();
    const [keyText, setKeyText] = useState<string>("");
    const [valText, setValText] = useState<string>("");

    const handleKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyText(e.target.value);
    };

    const handleValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValText(e.target.value);
    };

    const handleAddKeyVal = () => {
    setData((prevData) => ({
        ...prevData,
        toolSchema: {
        ...prevData.toolSchema,
        obj: {
            ...prevData.toolSchema.obj,
            [keyText]: valText,
        },
        },
    }));
    setKeyText("");
    setValText("");
    };

    return (
    <div className="p-4 bg-gray-700 rounded-lg shadow-lg space-y-4">
        <form className="space-y-4">
        <div className="flex flex-col">
            <label className="text-gray-300 text-sm text-left mb-1">Key</label>
            <input
            className="bg-gray-600 border border-gray-500 rounded-md p-2 text-gray-100"
            type="text"
            value={keyText}
            onChange={handleKeyChange}
            placeholder="Enter key"
            />
        </div>
        <div className="flex flex-col">
            <label className="text-gray-300 text-sm text-left mb-1">Value</label>
            <input
            className="bg-gray-600 border border-gray-500 rounded-md p-2 text-gray-100"
            type="text"
            value={valText}
            onChange={handleValChange}
            placeholder="Enter value"
            />
        </div>
        </form>
        <button
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-md"
            type="button"
            onClick={handleAddKeyVal}
            >
            Add Key Value
        </button>
    </div>
    );
};