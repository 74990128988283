import React, { useEffect, useState ,useCallback } from 'react';
import { db } from '../services/config/firebase';
import { 
  collection, 
  doc, getDoc,
  setDoc, addDoc,
  query,
  onSnapshot ,
  Unsubscribe
} from "firebase/firestore";
import '../styles/SubscriptionsPage.scss';

// Main component for the Subscriptions page with a grid layout
export default function SubscriptionsPage() {
  return (
    <div className="subscriptions-page">
      <div className="subscriptions-grid">
        {/* Subscriptions Section */}
        <div className="subscriptions-zone">
          <h2>Subscriptions</h2>
          <Subscriptions />
        </div>

        {/* Tokens Section */}
        <div className="subscriptions-zone">
          <h2>Tokens</h2>
          <Tokens />
        </div>

        {/* Extra Tools Section */}
        <div className="subscriptions-zone">
          <h2>Extra Tools</h2>
          <ExtraTools />
        </div>

        {/* Data Generator Section */}
        <div className="subscriptions-zone">
          <h2>Data Generator</h2>
          <DataGenerator />
        </div>
      </div>
    </div>
  );
}

// Component to visualize subscription data from Firestore
export function Subscriptions() {
  const [subscriptions, setSubscriptions] = useState<Sale[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    //rquest made to fire base
    const subscriptionsRef = collection(db, 'transactions', 'subscriptions', 'sales');
    const q = query(subscriptionsRef);
    const CACHE_KEY = 'subscriptionsData';// cahche key
    const CACHE_TTL = 5 * 60 * 1000; // 5 minutes chache time in sec

    const fetchData = () => {
      const cachedData = localStorage.getItem(CACHE_KEY);
      const cacheTime = localStorage.getItem(`${CACHE_KEY}_timestamp`);
      // check the cahche available or not if yes then does it pass the time of 5 min if no then it will get the data from cahce
      if (cachedData && cacheTime && Date.now() - parseInt(cacheTime, 10) < CACHE_TTL) {
        setSubscriptions(JSON.parse(cachedData));
        setIsLoading(false);
      } else {
        // getting the newest data through onSnapshot
        const unsubscribe: Unsubscribe = onSnapshot(
          q,
          (querySnapshot) => {
            const salesData: Sale[] = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })) as Sale[];

            setSubscriptions(salesData);
            setIsLoading(false);
            //setting the data in localstore for caching
            localStorage.setItem(CACHE_KEY, JSON.stringify(salesData));
            localStorage.setItem(`${CACHE_KEY}_timestamp`, Date.now().toString());
          },
          (error) => {
            setError('Failed to fetch subscription sales data.');
            setIsLoading(false);
            console.error('Error fetching subscription:', error);
          }
        );

        return () => unsubscribe();
      }
    };

    fetchData();
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="subscriptions-zone-content">
      <table className="sales-table">
        <thead>
          <tr className='table-row'>
            <th>ID</th>
            <th>Amount</th>
            <th>Currency</th>
            <th>Timestamp</th>
            <th>UID</th>
            <th>Subscription ID</th>
            <th>Token Amount</th>
            <th className='text-green'>Payment Method</th>
            <th>Status</th>
            <th>Product ID</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.length > 0 ? (
            subscriptions.map((sale) => (
              <tr key={sale.id}>
                <td>{sale.id}</td>
                <td>{sale.amount}</td>
                <td>{sale.currency}</td>
                <td>{new Date(sale.timestamp).toLocaleString()}</td>
                <td>{sale.uid}</td>
                <td>{sale.subscriptionId || '-'}</td>
                <td>{sale.tokenAmount ?? '-'}</td>
                <td>{sale.paymentMethod}</td>
                <td>{sale.status}</td>
                <td>{sale.productId || '-'}</td>
                <td>{sale.description || '-'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={11}>No subscription data available.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

// Component to visualize token sales data from Firestore
export function Tokens() {
  const [tokens, setTokens] = useState<TokenSale[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const tokensRef = collection(db, 'transactions', 'tokens', 'sales');
    //making reuest to the db collection
    const q = query(tokensRef);
    const CACHE_KEY = 'tokensData';
    //creting a cahe token and time
    const CACHE_TTL = 5 * 60 * 1000; // 5 minutes

    //getting the cachekey and time 
    const cachedData = localStorage.getItem(CACHE_KEY);
    const cacheTime = localStorage.getItem(`${CACHE_KEY}_timestamp`);
          // check the cahche available or not if yes then does it pass the time of 5 min if no then it will get the data from cahce
    if (cachedData && cacheTime && Date.now() - parseInt(cacheTime, 10) < CACHE_TTL) {
      setTokens(JSON.parse(cachedData));
      setIsLoading(false);
    } else {
      const unsubscribe: Unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const tokenSales: TokenSale[] = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as TokenSale[];

          setTokens(tokenSales);
          setIsLoading(false);
          localStorage.setItem(CACHE_KEY, JSON.stringify(tokenSales));
          localStorage.setItem(`${CACHE_KEY}_timestamp`, Date.now().toString());
        },
        (error) => {
          setError('Failed to fetch token sales data. Please try again later.');
          setIsLoading(false);
          console.error('Error fetching token sales data:', error);
        }
      );

      return () => unsubscribe();
    }
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="subscriptions-zone-content">
      <table className="sales-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Amount</th>
            <th>Currency</th>
            <th>Timestamp</th>
            <th>User ID</th>
            <th>Token Amount</th>
            <th>Payment Method</th>
            <th>Status</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {tokens.map((sale) => (
            <tr key={sale.id}>
              <td>{sale.id}</td>
              <td>{sale.amount}</td>
              <td>{sale.currency}</td>
              <td>{new Date(sale.timestamp).toLocaleString()}</td>
              <td>{sale.userId}</td>
              <td>{sale.tokenAmount}</td>
              <td>{sale.paymentMethod}</td>
              <td>{sale.status}</td>
              <td>{sale.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

// Component to generate random documents for testing Firestore listeners
export function DataGenerator() {
    // State to store the generated random document

  const [randomDoc, setRandomDoc] = useState<DocumentData | null>(null);

    // Arrays of static options for some fields

  const currencies = ['USD', 'EUR', 'GBP', 'JPY', 'CAD'];
  const paymentMethods = ['credit_card', 'paypal', 'bank_transfer'];
  const statuses = ['pending', 'completed', 'failed'];
  // Function to generate a random user ID

  const generateRandomUserId = () => Math.random().toString(36).substring(2, 12);
  // Function to generate a random document

  const generateRandomDoc = () => {
    const newDoc = {
      amount: Math.floor(Math.random() * 5000),
      currency: currencies[Math.floor(Math.random() * currencies.length)],
      timestamp: new Date().toISOString(),
      userId: generateRandomUserId(),
      tokenAmount: Math.floor(Math.random() * 1000),
      paymentMethod: paymentMethods[Math.floor(Math.random() * paymentMethods.length)],
      status: statuses[Math.floor(Math.random() * statuses.length)],
      description: `Purchase of ${Math.floor(Math.random() * 1000)} tokens`,
    };

    setRandomDoc(newDoc);
  };

  const writeToFirestore = async () => {
    if (randomDoc) {
      const tokensRef = doc(collection(db, 'transactions/tokens/sales'));
      await setDoc(tokensRef, randomDoc);
      console.log('Document written to Firestore:', randomDoc);
      setRandomDoc(null);
    }
  };

  return (
    <div className="subscriptions-zone-content">
      <div className="dataButtons">
        <button className="simple-button1" onClick={generateRandomDoc}>
          Generate Random Document
        </button>
        {randomDoc && (
          <button className="simple-button2" onClick={writeToFirestore}>
            Write to Firestore
          </button>
        )}
      </div>

      {randomDoc && (
        <div className="docs">
          {'{'}
          <pre className="json-pre">
            {Object.entries(randomDoc).map(([key, value]) => (
              <div style={{ padding: '2px 0' }} key={key}>
                "{key}": "{value}",
              </div>
            ))}
          </pre>
          {'}'}
        </div>
      )}
    </div>
  );
}

// Component to simulate extra tools for subscriptions
export function ExtraTools() {
  const [isActive, setIsActive] = useState(false);

  const handleActivate = () => {
    setIsActive(true);
  };

  const handleDeactivate = () => {
    setIsActive(false);
  };

  return (
    <div className="subscriptions-zone-content">
      <button className={`simple-button ${isActive ? 'active' : ''}`} onClick={handleActivate}>
        Activate Extra Tools
      </button>
      {isActive && (
        <button className="simple-button" onClick={handleDeactivate}>
          Deactivate Extra Tools
        </button>
      )}
    </div>
  );
}

interface Sale {
  id: string;
  amount: number;
  currency: string;
  timestamp: string;
  uid: string;
  subscriptionId?: string;
  tokenAmount?: number;
  paymentMethod: string;
  status: string;
  productId?: string;
  description?: string;
}

interface TokenSale {
  id: string;
  amount: number;
  currency: string;
  timestamp: string;
  userId: string;
  tokenAmount: number;
  paymentMethod: string;
  status: string;
  description: string;
}

export interface DocumentData {
  amount: number;
  currency: string;
  timestamp: string;
  userId: string;
  tokenAmount: number;
  paymentMethod: string;
  status: string;
  description: string;
}
