import React from 'react';
import { Subscription } from '../../../../types/config';
import { useConfig } from '../../../contexts/ConfigContext';

interface SubscriptionListProps {
    allPlans: Subscription[];
}

const SubscriptionList: React.FC<SubscriptionListProps> = ({  allPlans }) => {
// This is the Component where there is list of all the plans
// The setSubscriptionModel is use to open model & setEditSubscribtionPlan is used for passing the clicked plan data
    const { setSubscriptionModel,setEditSubscribtionPlan }= useConfig()


// it will open model and send plan to setEditSubscribtionPlan
    const handlEditeModel = (plan:Subscription) => {
      setSubscriptionModel(true)
      setEditSubscribtionPlan(plan)
    }

    return (
        <>
            {/* Sidebar */}
            <div
      className={`flex h-[150vh]  hs-overlay [--auto-close:lg] translate-x-0 transition-all duration-300 transform  bottom-0 z-[60] w-64 bg-white border-e border-gray-200 pt-7 pb-10 overflow-y-auto lg:block lg:end-0 lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-neutral-700 dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500 dark:bg-neutral-800 dark:border-neutral-700`}
      role="dialog"
      aria-label="Sidebar"
    >
      <div className="px-6 flex justify-between items-center">
        <div className="flex-none font-semibold text-xl text-black focus:outline-none focus:opacity-80 dark:text-white">
          All Subscription Plans
        </div>
       
      </div>

      <nav
        className="hs-accordion-group p-6 w-full flex flex-col flex-wrap"
        data-hs-accordion-always-open
      >
      <ul className="space-y-1.5">
  {/* Displaying the list of all subscription plans */}
  {allPlans.map((item) => (
    <li key={item.id}>
      <button
        className="flex  w-full items-center gap-x-3.5 py-2 px-2.5 bg-gray-700 text-left text-sm text-gray-700 rounded-lg hover:bg-gray-700 hover:text-black dark:bg-neutral-700 dark:text-white cursor-pointer"
        onClick={() => handlEditeModel(item)}
      >
        <div className="text-left">
          <p className='text-gray-200 '><strong>ID:</strong> 
            {item.id}
            </p>
          <p className='text-gray-200 '><strong>Name:</strong> {item.name}</p>
          <p className='text-gray-200 '><strong>Price:</strong> {item.price}</p>
          <p className='text-gray-200 '><strong>Currency:</strong> {item.currency}</p>
        </div>
      </button>
    </li>
  ))}
  {/* Add more menu items here if needed */}
</ul>

      </nav>
    </div>
        </>
    );
}

export default SubscriptionList;
