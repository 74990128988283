import React, { useState,useEffect } from 'react';
import '../styles/ConfigPage.scss';
//SubscriptionPlan is a Component which displays all the available 

import SubscriptionPlan from '../interface/components/configcomp/SubscriptionPlan';

//The UiTab is a Component use to toggle between Subscription Plan LLM Config General Config

import UiTab from '../interface/components/configcomp/ui/UiTab';
//useConfig is is callFunctionfor Context Api
import { useConfig } from '../interface/contexts/ConfigContext';
//This is LLM Component
import LlmConfig from '../interface/components/configcomp/LlmConfig';
//This is GeneralConfig Component

import GeneralConfig from '../interface/components/configcomp/GeneralConfig';
//Subscription are the types used for Subscription plan
import { Subscription } from '../types/config';
// fetchAvailablePlans,fetchAllPlans is a function store seperatly in ../services/firebase to call all Availble plan and All plan
import { fetchAvailablePlans,fetchAllPlans } from '../services/firebase';
// SubscriptionList Component is used to Display all Subscription Plan List on a side Bar
import SubscriptionList from '../interface/components/configcomp/ui/SubscriptionList';
// A Model is Used to Display the plan ,Add ,Edit 
import Model from '../interface/components/configcomp/ui/Model';
// It is a Confirmation Box which is used for confirm the delete 
import ConfirmBox from '../interface/components/configcomp/ui/ConfirmBox';


// Subscribtion Tab Describtion
//There are three tabs: Subscription, LLM Config, and General Config. In the Subscription tab, there are two components: SubscriptionPlan and SubscriptionList.
// SubscriptionPlan displays all available plans and includes a button to add a new plan.
// SubscriptionList shows all existing plans. By clicking on a plan, a modal will open.
// There is also a modal component used for adding, deleting, and editing plans.


// LLm Tab Describtion
// There are Two Component in LLM component Which is LLmTable and LLmInitChat
// The LLM Table has MissingInfo Data where can Update Prompt 
// the LLM InitChat has Text box and Preview Box where we can update the Init text and also Preview It.




  
  const ConfigPage: React.FC = () => {
    const [allPlans, setallPlans] = useState<Subscription[]>([]);// this is a state use for store all avaialble plan
    

    // selectedTab is to see which tab is selected 
    //  reRender is use for calling the useEffect again and fetching the data when we do add or delte or edit 
    const { selectedTab , reRender ,setAvailable} = useConfig()// This data is coming from Context SelectedTap  is for toggle betweeb the subscribtion plan llm general config reRender is used for rerender the state for allsubs when added new

// This UseEffect is used to fetch All the data and pass it to the SubscriptionList Conponent
    useEffect(() => {
      const fetchPlans = async () => {
        const allplan = await fetchAllPlans(); // This should now return allSubscription[]
        setallPlans(allplan)
      };
      fetchPlans();
    }, [reRender]);
// This UseEffect is used to fetch All Available  plan  data and pass it to the Context (beacause we want to use it in SubscriptionPlan and model).

    useEffect(() => {
      const fetchAvailPlan = async () => {
        const avaialblePlan = await fetchAvailablePlans();
        setAvailable(avaialblePlan)
      }
      fetchAvailPlan()
    },[reRender])

  
    return (
      <div  className=" ">
        {/* LLm Components */}
        {/* it is used for toggle between tab */}
        <UiTab/>
        {/* model use for add new subscribtion list and edit it */}
        <Model/>
        {/* This is subscibtion list component */}
        {/* confirmBox is cofirmation for deleting the plan */}
        <ConfirmBox/>
        {/* subscribtionList component is the component whcih shows all the list of subscibtion plan on the sideBar */}
        <h1 className='mt-10 text-gray-200 text-4xl'>Configuration Dashboard</h1>

        <div  className="">
          {/* Subscibtion  */}
          {/* SubsctionPlan Display all the Available plan and from where we can remove availble plan from the available collection and also can edit */}
          <div className='flex w-full justify-between mt-10 '>

          {selectedTab === 'subscriptions' && <SubscriptionPlan />}
          {selectedTab === 'subscriptions' &&  <SubscriptionList allPlans={allPlans} />}

       
          </div>
          {/* llmConfig tab  Displays the LLM  ToolSchema and InitChat */}
          {selectedTab === 'llm' && <LlmConfig/> }
          {/* generalConfig tab */}
          {selectedTab === 'general' && <GeneralConfig/>}


          
        </div>
      </div>
    );
  };
  
  export default ConfigPage;