// src/pages/LoginPage.tsx
import React, { useEffect, useState } from 'react';
import { 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword 
} from 'firebase/auth';
import { auth } from '../services/config/firebase';
import { useNavigate } from 'react-router-dom';
import '../styles/LoginPage.scss';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // if (isRegistering) {
      //   await createUserWithEmailAndPassword(auth, email, password);
      //   navigate('/');
      // } else {
      //   await signInWithEmailAndPassword(auth, email, password);
      //   navigate('/');
      // }

      const isAuth = await signInWithEmailAndPassword(auth, email, password);
      // if the user has been authenticated, redirect to the home page
      if (isAuth) {
        navigate('/');
      }
      
    } catch (error) {
      console.error('Failed to log in', error);
      setError('Failed to log in');
    }
  };

  useEffect(() => {}, []);


  return (
    <div className="login-page">
      <div className="login-card">
        <div className="login-header">
          <h1>SERIPH</h1>
        </div>
        {isRegistering ? (
          <h2>Register with email</h2>
          ): (
            <h2>Sign in with email</h2>
          )}
        
        <p></p>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="input-group text-black">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <br />
            <br />

            <span className="forgot-password">Forgot password?</span>
          </div>
          <button type="submit" className="login-button">
            Get Started
          </button>
        </form>
        {isRegistering ? (
          <p>
            Already have an account?{' '}
            <button onClick={() => setIsRegistering(false)}>Sign in</button>
          </p>
        ) : (
          <p>
            Don't have an account?{' '}
            <button onClick={() => setIsRegistering(true)}>Register</button>
          </p>
        )}
      </div>
    </div>
  );
};

export default LoginPage;