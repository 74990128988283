import React, { useEffect, useState } from 'react';
import { fetchInitChat } from '../../../../services/firebase'; // Import necessary functions from services
import { InitChat } from '../../../../types/config'; // Import InitChat type for TypeScript support
import { toast } from 'react-toastify'; // Import React Toastify for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
import { useConfig } from '../../../contexts/ConfigContext'; // Import custom hook to use the context for InitChat

const LlmInit: React.FC = () => {
  // Local state to hold the initial chat data fetched from Firebase
  const [initChat, setInitChat] = useState<InitChat | null>(null);

  // State to hold the text that is being edited in the textarea
  const [editableText, setEditableText] = useState<string>(''); 

  // Get the data and setter for InitChat from the context
  const {  setDatainitChat } = useConfig();

  // Sync the context state with the local editableText when initChat changes
  useEffect(() => {
    // Check if initChat is defined and if the text has changed
    if (initChat && initChat.text !== editableText) {
      setDatainitChat({ id: initChat.id, text: editableText }); // Update the context with the new text
    }
  }, [editableText]); // Dependency array ensures this runs when editableText changes

  // Fetch initial chat data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch InitChat data from Firebase
        const chatData = await fetchInitChat();
        if (chatData) {
          setInitChat(chatData); // Set the fetched chat data to the local state
          setEditableText(chatData.text); // Set the editable text to the fetched data's text
        }
      } catch (error) {
        // Show an error toast notification if fetching data fails
        toast.error('Failed to fetch InitChat data. Please try again later.');
      }
    };

    fetchData(); // Call the fetchData function
  }, []); // Empty dependency array means this effect runs once when the component mounts

  // Handle input change in the textarea
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditableText(e.target.value); // Update the editableText state with the new input value
  };

  return (
    <div className="p-4">
      {/* Text area for editing the initChat string */}
      <div className="mb-4">
        {/* Label for the textarea */}
        <label htmlFor="initChat" className="block text-sm font-medium text-gray-900 dark:text-white mb-2">
          Edit InitChat:
        </label>
        {/* Textarea input field bound to editableText */}
        <textarea
          id="initChat"
          value={editableText} // Show the current editable text
          onChange={handleInputChange} // Handle input changes
          rows={8}
          className="block w-full p-2.5 bg-gray-50 border border-gray-300 text-sm text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
          placeholder="Edit the initChat text here"
        />
      </div>

      {/* Preview of how the initChat prompt will appear */}
      <div>
        {/* Header for the preview section */}
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">Preview of InitChat Prompt:</h3>
        {/* Display the editableText in a formatted block */}
        <pre className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg text-gray-900 dark:text-gray-200">
          {editableText} {/* Show the editable text */}
        </pre>
      </div>
    </div>
  );
};

export default LlmInit;
