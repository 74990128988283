import { db } from './config/firebase'; // Import your Firebase configuration
import { collection, doc, getDocs, getDoc,Unsubscribe, setDoc, addDoc, deleteDoc, updateDoc,query,orderBy ,Timestamp,onSnapshot} from 'firebase/firestore'; // Import Firestore methods
import { InitChat, MissingInfoSchema, Subscription } from '../types/config'; // Import your type

// Creates a new plan in the 'plans' collection
export const createPlan = async (plan: Omit<Subscription, 'id'>, isAvailable: boolean): Promise<void> => {
  try {
    const colRef = collection(db, 'config/subscription/plans');
    
    // Add createdAt timestamp to the plan object
    const planWithTimestamp = {
      ...plan,
      createdAt: Timestamp.now() // Add createdAt timestamp
    };

    const docRef = await addDoc(colRef, planWithTimestamp);
    console.log('New plan created with ID:', docRef.id);

    if (isAvailable) {
      await addPlanToAvailablePlans(docRef.id);
    }
  } catch (error) {
    console.error('Error creating plan:', error);
  }
};

// Update subscription plan function

export const updatePlan = async (
  planId: string, // Pass the plan ID here
  updatedPlan: Omit<Subscription, 'id'>, // Omit 'id' since it's already passed
  isAvailable: boolean
): Promise<void> => {
  try {
    // Reference to the specific plan document
    const docRef = doc(db, 'config/subscription/plans', planId);

    // Add createdAt timestamp to the updatedPlan object (if it doesn't exist)
    const planWithCreatedAt = {
      ...updatedPlan,
      createdAt: Timestamp.now() // Add createdAt timestamp
    };

    // Update the document with the new plan data
    await updateDoc(docRef, planWithCreatedAt);


    // Check if the plan should be available
    if (isAvailable) {
      await addPlanToAvailablePlans(planId);
    } 
  } catch (error) {
    console.error('Error updating plan:', error);
  }
};


// Delete plan function
export const deletePlan = async (planId: string): Promise<void> => {
  try {
    // Reference to the document that needs to be deleted
    const docRef = doc(db, 'config/subscription/plans', planId);

    // Deleting the document
    await deleteDoc(docRef);

    // Remove the plan from available plans (optional logic)
    await deleteAvailablePlan(planId);
  } catch (error) {
    console.error('Error deleting plan:', error);
  }
};

// Adds a document reference to the 'availablePlans' collection
const addPlanToAvailablePlans = async (planId: string): Promise<void> => {
  console.log('planId:', planId);
  try {
    const availablePlansRef = collection(db, 'config/subscription/availablePlans');
    const querySnapshot = await getDocs(availablePlansRef);
    const availablePlansCount = querySnapshot.size;

    if (availablePlansCount < 3) {
      // Add the plan with the current timestamp
      await addDoc(availablePlansRef, { 
        planId,
        createdAt: Timestamp.now() // Add createdAt timestamp
      });
      console.log('Plan added to available plans collection');
    } else {
      console.log('Maximum number of available plans reached');
    }
  } catch (error) {
    console.error('Error adding plan to available plans collection:', error);
  }
};

// Deletes a plan from available plans
export const deleteAvailablePlan = async (planId: string): Promise<void> => {
  try {
    const availablePlansRef = collection(db, 'config/subscription/availablePlans');
    const querySnapshot = await getDocs(availablePlansRef);

    // Find the document with the matching planId
    const docToDelete = querySnapshot.docs.find(doc => doc.data().planId === planId);

    if (docToDelete) {
      await deleteDoc(docToDelete.ref);
    } else {
      console.log(`Plan with ID ${planId} not found in available plans.`);
    }
  } catch (error) {
    console.error('Error deleting available plan:', error);
  }
};

// Fetches available plans by filtering from all plans based on document IDs
// Fetches available plans by filtering from all plans based on document IDs and sorting by index


export const fetchAvailablePlans = async (): Promise<Subscription[]> => {
  try {
    // Reference to the Firestore collection, ordered by `createdAt` timestamp
    const colRef = collection(db, 'config/subscription/availablePlans');

    // Create a query to order documents by the `createdAt` timestamp
    const q = query(colRef, orderBy('createdAt', 'asc')); // 'asc' for ascending, use 'desc' for descending order

    // Fetch the documents from Firestore based on the query
    const snapshot = await getDocs(q);

    // Extract `planId`, `index`, and `createdAt` from each document
    const availablePlansData = snapshot.docs.map(doc => ({
      planId: doc.data().planId,
      index: doc.data().index,
      createdAt: doc.data().createdAt, // Assuming `createdAt` is stored in Firestore
    }));

    console.log('availablePlansData:', availablePlansData);

    // Sort plans by `createdAt` timestamp (this should already be done by the Firestore query)
    const sortedPlansData = availablePlansData.sort((a, b) => a.createdAt - b.createdAt);

    // Fetch all plans (assuming it returns an array of `Subscription` objects)
    const allPlans = await fetchAllPlans();

    // Filter and sort plans where `id` matches any of the `planId` from Firestore, maintaining order by `createdAt`
    const sortedAvailablePlans = sortedPlansData
      .map(data => allPlans.find(plan => plan.id === data.planId))
      .filter(plan => plan !== undefined) as Subscription[]; // Filter out any undefined results

    return sortedAvailablePlans;
  } catch (error) {
    console.error('Error fetching available plans:', error);
    return []; // Return an empty array in case of error
  }
};



// Fetches all plans from the 'plans' collection
export const fetchAllPlans = async (): Promise<Subscription[]> => {
  try {
    const colRef = collection(db, 'config/subscription/plans');
    const snapshot = await getDocs(colRef);
    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }) as Subscription);
  } catch (error) {
    return []; // Return an empty array in case of error
  }
};

// Updates the available plans collection with new plan IDs
export const updateAvailablePlans = async (planIds: string[]): Promise<void> => {
  try {
    // Reference to the collection
    const collectionRef = collection(db, 'config/subscription/availablePlans');

    // Get all documents in the collection
    const querySnapshot = await getDocs(collectionRef);

    // Delete each document in the collection
    const deletePromises = querySnapshot.docs.map(doc => deleteDoc(doc.ref));
    await Promise.all(deletePromises);

    // Add new documents based on the planIds array
    const addPromises = planIds.map(async (planId, index) => {
      const docRef = doc(collectionRef, planId);
      await setDoc(docRef, { planId, index });
    });

    await Promise.all(addPromises);

    console.log('All new available plans added to the collection.');
  } catch (error) {
    console.error('Error updating available plans:', error);
  }
};

export const addToolSchema = async (newSchema: {
  userInput: string;
  missingFields: string;
  assistantAllInfoRequest: string;
  plainTextResponse: string;
  sensitiveInfo: string;
}): Promise<void> => {
  try {
    const docRef = await addDoc(collection(db, 'config/llm/toolSchema'), newSchema);
    console.log('Document written with ID: ', docRef.id);
  } catch (error) {
    console.error('Error adding tool schema:', error);
  }
};

// Fetches tool schema from Firestore


export const subscribeToToolSchema = (callback:any) => {
  try {
    const unsubscribe = onSnapshot(collection(db, 'config/llm/toolSchema'), (querySnapshot) => {
      const schemas = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id, // Ensure id is included
          userInput: data.userInput ?? '', // Fallback if missing
          missingFields: data.missingFields ?? '',
          assistantAllInfoRequest: data.assistantAllInfoRequest ?? '',
          plainTextResponse: data.plainTextResponse ?? '',
          sensitiveInfo: data.sensitiveInfo ?? ''
        };
      });

      console.log('Fetched tool schemas: ', schemas);
      callback(schemas); // Call the callback with the fetched schemas
    });

    // Return the unsubscribe function to stop listening when needed
    return unsubscribe;
  } catch (error) {
    console.error('Error subscribing to tool schema:', error);
    return null;
  }
};
//temp



// Fetches initial chat configuration
export const fetchInitChat = async (): Promise<InitChat | null> => {
  try {
    const querySnapshot = await getDocs(collection(db, 'config/llm/initChat'));
    console.log('querySnapshot::: ', querySnapshot);
    
    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0]; // Get the first document
      const data = doc.data();
      return { id: doc.id, text: data?.text ?? '' }; // Return the ID and chat text
    }
    
    return null; // Return null if no documents are found
  } catch (error) {
    console.error('Error fetching initial chat configuration:', error);
    return null; // Return null in case of an error
  }
};

export const updateChatById = async (id: string, newText: string): Promise<void> => {
  try {
    const chatDocRef = doc(db, 'config/llm/initChat', id); // Reference to the document by ID
    await updateDoc(chatDocRef, {
      text: newText, // Update the 'text' field
    });
    console.log('Document updated with ID:', id);
  } catch (error) {
    console.error('Error updating document:', error);
  }
};

// Updates tool schema in Firestore
export const updateToolSchema = async (id: string, schema: MissingInfoSchema): Promise<void> => {
  console.log('schema::: ', schema);
  try {
    // Get a reference to the document based on the provided id
    const docRef = doc(db, `config/llm/toolSchema/${id}`);
    
    // Update the document with the new schema data
    await setDoc(docRef, schema);
    
    console.log('Tool schema updated.');
  } catch (error) {
    console.error('Error updating tool schema:', error);
  }
};

// Updates initial chat configuration
export const updateInitChat = async (chat: string): Promise<void> => {
  try {
    const docRef = doc(db, 'config/llm/initChat');
    await setDoc(docRef, { chat });
    console.log('Initial chat configuration updated.');
  } catch (error) {
    console.error('Error updating initial chat configuration:', error);
  }
};

// Fetches all general configurations
// export const fetchAllConfigs = async (): Promise<any[]> => {
//   try {
//     // Implement your logic here for fetching general configurations
//   } catch (error) {
//     console.error('Error fetching all configurations:', error);
//     return [];
//   }
// };

// Updates a specific configuration
export const updateConfig = async (config: any): Promise<void> => {
  try {
    // Implement your logic here for updating a specific configuration
  } catch (error) {
    console.error('Error updating configuration:', error);
  }
};
