import React from 'react'

const GeneralConfig: React.FC = () => {
  return (
    <div>
      general
    </div>
  )
}

export default GeneralConfig
