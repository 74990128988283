// Import Firebase configuration
import { database } from '../../config/firebase'; // Assuming db is the initialized Realtime Database reference

// Import Firebase Realtime Database methods
import {
  ref, set, get, update, remove, onValue, push, DataSnapshot, child
} from 'firebase/database';

/**
 * @class RealtimeDBManager
 * @description Singleton Class Manages Firebase Realtime Database operations
 */
export class RealtimeDBManager<T> {

    // Singleton instance
    private static instance: RealtimeDBManager<any>;

    /**
     * @method getInstance
     * @description Returns a singleton instance of RealtimeDBManager
     * @returns {RealtimeDBManager} A singleton instance of RealtimeDBManager
     */
    public static getInstance<T>(): RealtimeDBManager<T> {
        if (!RealtimeDBManager.instance) {
            RealtimeDBManager.instance = new RealtimeDBManager();
        }
        return RealtimeDBManager.instance;
    }

    //----------------------------------------------------------------
    // Basic CRUD Operations

    /**
     * @method writeData
     * @description Writes data to a specific path in the Realtime Database
     * @param path {string} The path where the data should be written
     * @param data {T} The data to be written
     * @returns {Promise<void>} Resolves when the write operation is complete
     */
    public async writeData(path: string, data: T): Promise<void> {
        try {
            const dbRef = ref(database, path);
            await set(dbRef, data);
            console.log(`Data written to path: ${path}`);
        } catch (error) {
            console.error(`Failed to write data to path ${path}:`, error);
            throw error;
        }
    }

    /**
     * @method getData
     * @description Reads data from a specific path in the Realtime Database
     * @param path {string} The path to read from
     * @returns {Promise<T | null>} Resolves with the data at the given path or null if it doesn't exist
     */
    public async getData(path: string): Promise<T | null> {
        try {
            const dbRef = ref(database, path);
            const snapshot = await get(dbRef);
            if (snapshot.exists()) {
                return snapshot.val();
            } else {
                console.log("No data found at the given path.");
                return null;
            }
        } catch (error) {
            console.error(`Failed to read data from path ${path}:`, error);
            throw error;
        }
    }

    /**
     * @method updateData
     * @description Updates data at a specific path in the Realtime Database
     * @param path {string} The path to update
     * @param data {Partial<T>} Partial data to update at the path
     * @returns {Promise<void>} Resolves when the update operation is complete
     */
    public async updateData(path: string, data: Partial<T>): Promise<void> {
        try {
            const dbRef = ref(database, path);
            await update(dbRef, data);
            console.log(`Data updated at path: ${path}`);
        } catch (error) {
            console.error(`Failed to update data at path ${path}:`, error);
            throw error;
        }
    }

    /**
     * @method deleteData
     * @description Deletes data from a specific path in the Realtime Database
     * @param path {string} The path to delete
     * @returns {Promise<void>} Resolves when the delete operation is complete
     */
    public async deleteData(path: string): Promise<void> {
        try {
            const dbRef = ref(database, path);
            await remove(dbRef);
            console.log(`Data deleted at path: ${path}`);
        } catch (error) {
            console.error(`Failed to delete data from path ${path}:`, error);
            throw error;
        }
    }

    //----------------------------------------------------------------
    // Push Data (for lists of items)

    /**
     * @method pushData
     * @description Pushes data to a list at a specific path in the Realtime Database (creates a unique ID)
     * @param path {string} The path to push to
     * @param data {T} The data to push
     * @returns {Promise<string>} Resolves with the unique key of the new data
     */
    public async pushData(path: string, data: T): Promise<string> {
        try {
            const dbRef = ref(database, path);
            const newRef = push(dbRef);
            await set(newRef, data);
            console.log(`Data pushed to path: ${path}`);
            return newRef.key!;
        } catch (error) {
            console.error(`Failed to push data to path ${path}:`, error);
            throw error;
        }
    }

    //----------------------------------------------------------------
    // Real-time Listeners

    /**
     * @method listenToData
     * @description Listens to real-time updates at a specific path in the Realtime Database
     * @param path {string} The path to listen to
     * @param onUpdate {(data: T | null) => void} Callback fired when data changes
     * @returns {() => void} Returns an unsubscribe function to stop listening
     */
    public listenToData(path: string, onUpdate: (data: T | null) => void): () => void {
        const dbRef = ref(database, path);

        const unsubscribe = onValue(dbRef, (snapshot: DataSnapshot) => {
            if (snapshot.exists()) {
                onUpdate(snapshot.val());
            } else {
                onUpdate(null);
            }
        }, (error) => {
            console.error(`Failed to listen to data at path ${path}:`, error);
        });

        return () => unsubscribe(); // Return unsubscribe function to stop listening
    }

    /**
     * @method listenToChildAdded
     * @description Listens to children being added to a list at a specific path in real-time
     * @param path {string} The path to listen for added children
     * @param onChildAdded {(childData: T) => void} Callback fired when a new child is added
     * @returns {() => void} Returns an unsubscribe function to stop listening
     */
    public listenToChildAdded(path: string, onChildAdded: (childData: T) => void): () => void {
        const dbRef = ref(database, path);

        const unsubscribe = onValue(dbRef, (snapshot: DataSnapshot) => {
            snapshot.forEach((childSnapshot) => {
                onChildAdded(childSnapshot.val());
            });
        }, (error) => {
            console.error(`Failed to listen for child added at path ${path}:`, error);
        });

        return () => unsubscribe(); // Return unsubscribe function to stop listening
    }

    //----------------------------------------------------------------
    // Transaction Handling

    /**
     * @method runTransaction
     * @description Runs a transaction to update data atomically at a specific path
     * @param path {string} The path to run the transaction on
     * @param transactionUpdate {(currentData: T | null) => T} Function that updates the current data
     * @returns {Promise<T | null>} Resolves with the new data after the transaction is committed
     */
    public async runTransaction(path: string, transactionUpdate: (currentData: T | null) => T): Promise<T | null | void> {
        const dbRef = ref(database, path);
        try {
            const result = await update(dbRef, transactionUpdate);
            return result;
        } catch (error) {
            console.error(`Failed to run transaction on path ${path}:`, error);
            throw error;
        }
    }
}
